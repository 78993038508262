import { blueScale, blueSteelScale } from "colors";
import { type Dispatch, type ReactNode } from "react";
import { Body, Card, CardBody, Checkbox, isDarkMode } from "ui";

import { FormattedMessage } from "react-intl";

type CloudCardProps = {
  icon: ReactNode;
  label: string;
  content: string;
  onChange: Dispatch<React.SetStateAction<string>>;
  isSelected: boolean;
  disabled?: boolean;
};

export default function CloudCard({
  icon,
  label,
  content,
  onChange,
  isSelected,
  disabled,
}: CloudCardProps) {
  const isDark = isDarkMode();

  const selectedCardBg =
    !disabled && isSelected && !isDark
      ? { background: blueScale[100] }
      : !disabled && isSelected && isDark
        ? { background: blueSteelScale[850] }
        : {};

  return (
    <Card
      addClassName="my-2 ml-1.5 cursor-pointer hover:bg-blue-100 dark:hover:bg-blue-700"
      style={selectedCardBg}
    >
      <CardBody addClassName="relative flex">
        <Checkbox
          disabled={disabled}
          checked={isSelected}
          onChange={() => onChange(label)}
          addClassName="absolute left-0 top-0 h-full w-full border-0"
          style={{ background: "transparent" }}
        />
        <Body as="div" addClassName="h-fit p-1">
          {icon}
        </Body>
        <Body as="div" addClassName="pl-2 pt-1">
          <Body addClassName="font-bold">
            <FormattedMessage
              defaultMessage="{label}"
              id="NbFno+"
              description="Cloud Card header"
              values={{
                label,
              }}
            />
          </Body>
          <Body addClassName="text-xs">
            <FormattedMessage
              defaultMessage="{content}"
              id="ZoHLkz"
              description="Cloud Card content"
              values={{
                content,
              }}
            />
          </Body>
        </Body>
      </CardBody>
    </Card>
  );
}
