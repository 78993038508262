import { FormattedMessage, useIntl } from "react-intl";

import { Accordion, AccordionPanel } from "ui";

import { useEnabledFeatures } from "./useEnabledFeatures";

import { type Features } from "requests";
import {
  CheckboxRow,
  Row,
  SelectAllCheckbox,
  useOperationSelectAll,
} from "../components";

export const dashboardFeatures: Features[] = [
  {
    featureName: "codeSecurityDashboard",
    operations: {
      READ: false,
    },
  },
  {
    featureName: "vulnerabilityDashboard",
    operations: {
      READ: false,
    },
  },
];

export function DashboardSection() {
  const intl = useIntl();
  const { isLoading, isFeatureEnabled } = useEnabledFeatures();
  const availableFeatures = dashboardFeatures.filter((feature) =>
    isFeatureEnabled(feature.featureName),
  );

  const { selectionStatus, onClickSelectAll } =
    useOperationSelectAll(availableFeatures);

  if (isLoading || !availableFeatures.length) return null;

  return (
    <Accordion allowNoneOpen allowMultipleOpen>
      <AccordionPanel
        title={intl.formatMessage({
          defaultMessage: "Dashboard",
          id: "4vFAyY",
          description: "Assign permissions section header",
        })}
        noPadding
        testId="permission-accordion"
      >
        <div className="divide-y divide-primary">
          <Row
            label={intl.formatMessage({
              defaultMessage: "Select All",
              id: "aYkfGO",
              description: "Select all checkbox row",
            })}
            addClassName="border-b-2"
            read={
              <SelectAllCheckbox
                status={selectionStatus.read}
                onChange={() => {
                  onClickSelectAll("READ");
                }}
              >
                <FormattedMessage
                  defaultMessage="Select view for all dashboard rows"
                  id="jJ/dQH"
                  description="Permission group dashboard section header"
                />
              </SelectAllCheckbox>
            }
          />

          {availableFeatures.map((feature) => {
            return <CheckboxRow key={feature.featureName} feature={feature} />;
          })}
        </div>
      </AccordionPanel>
    </Accordion>
  );
}
