import { FormattedMessage, useIntl } from "react-intl";

import { Accordion, AccordionPanel } from "ui";

import { useEnabledFeatures } from "./useEnabledFeatures";

import { type Features } from "requests";
import {
  CheckboxRow,
  Row,
  SelectAllCheckbox,
  useOperationSelectAll,
} from "../components";

export const assetInventoryFeatures: Features[] = [
  {
    featureName: "assetInventoryOverview",
    operations: {
      READ: false,
    },
  },
];

export function AssetInventorySection() {
  const intl = useIntl();
  const { isLoading, isFeatureEnabled } = useEnabledFeatures();
  const availableFeatures = assetInventoryFeatures.filter((feature) =>
    isFeatureEnabled(feature.featureName),
  );

  const { selectionStatus, onClickSelectAll } =
    useOperationSelectAll(availableFeatures);

  if (isLoading) return null;

  if (!availableFeatures.length) {
    return null;
  }

  return (
    <Accordion allowNoneOpen allowMultipleOpen defaultOpenPanels={[]}>
      <AccordionPanel
        title={intl.formatMessage({
          defaultMessage: "Asset Inventory",
          id: "BG7uTo",
          description: "Assign permissions section header",
        })}
        noPadding
        testId="permission-accordion"
      >
        <div className="divide-y divide-primary">
          <Row
            label={intl.formatMessage({
              defaultMessage: "Select All",
              id: "aYkfGO",
              description: "Select all checkbox row",
            })}
            addClassName="border-b-2"
            read={
              <SelectAllCheckbox
                status={selectionStatus.read}
                onChange={() => {
                  onClickSelectAll("READ");
                }}
              >
                <FormattedMessage
                  defaultMessage="Select view for all asset inventory rows"
                  id="rcZo+Z"
                  description="Permission group asset inventory section header"
                />
              </SelectAllCheckbox>
            }
          />

          {availableFeatures.map((feature) => {
            return <CheckboxRow key={feature.featureName} feature={feature} />;
          })}
        </div>
      </AccordionPanel>
    </Accordion>
  );
}
