import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { usePermissions } from "prisma";
import {
  getTenantFeatures,
  tenantKeys,
  useIsSystemAdmin,
  useLicenseInfo,
  useToken,
} from "requests";
// import { useDlpStatus } from "../useDlpStatus";

// TODO: uncomment DLP lines when RLP-140723 is ready
export function useDspmStatus(): {
  isSuccess: boolean;
  isError: boolean;
  isDspmEnabled: boolean;
  showDspm: boolean;
  allowSubscribe: boolean;
  showDlp: boolean;
  dspmProvisionStatus: string;
} {
  const { dspmSubscribe } = useFlags();
  const sysAdmin = useIsSystemAdmin();
  const {
    dataSecurityPostureManagement: { UPDATE },
  } = usePermissions();
  const { prismaId, selectedCustomerName: customerName } = useToken();
  const { data: licenseInfo } = useLicenseInfo();
  const { licenseType } = licenseInfo ?? {};

  const {
    data: features,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: tenantKeys.features({ customerName, prismaId }),
    queryFn: getTenantFeatures,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const dspmFeature = features?.find(
    (feature) => feature["feature_name"] === "dspm",
  );

  const dspmIsProvisionedAndEnabled =
    dspmFeature?.state === "PROVISION_SUCCESSFUL" && dspmFeature?.enabled;

  const allowSubscribe =
    licenseType?.toLowerCase() === "enterprise" && sysAdmin && dspmSubscribe;

  // const { data: dlpStatus } = useDlpStatus();
  // const isDlpProvisioned = dlpStatus?.status === "provisionSuccessful";

  return {
    isSuccess,
    isError,
    showDspm:
      (sysAdmin || UPDATE) && dspmSubscribe && dspmIsProvisionedAndEnabled,
    isDspmEnabled: !!dspmFeature?.enabled,
    allowSubscribe,
    dspmProvisionStatus: dspmFeature?.state ?? "",
    // showDlp: !dspmSubscribe || isDlpProvisioned,
    showDlp: true,
  };
}
