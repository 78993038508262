import { type ReactElement, type ReactNode } from "react";
import { TruncateText } from "ui";

export interface JoinTruncateCellProps {
  value?: ReactNode[];
}

export function JoinTruncateCell({
  value = [],
}: JoinTruncateCellProps): ReactElement {
  return <TruncateText>{value?.join(", ")}</TruncateText>;
}
