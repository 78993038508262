import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
// import { useMutation, useQueryClient } from "react-query";

import { useToastActions } from "stores";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  type RenderModalProps,
} from "ui";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isNil } from "remeda";
import { deleteUserRole, rolesKeys } from "requests";
import { type UserRole } from "../../../../users/Forms/User/UserForm";
import Table from "./Table";

export default function DeleteModal({
  id,
  isOpen,
  closeModal,
  deleteConflictsMap,
  name,
}: RenderModalProps<UserRole>) {
  const intl = useIntl();
  const { toast } = useToastActions();

  let modalBody = (
    <FormattedMessage
      defaultMessage="Are you sure you want to delete this role?"
      id="4FuGH6"
      description="Delete confirmation message"
    />
  );

  const deleteConflictsMapForId =
    deleteConflictsMap[id as keyof typeof deleteConflictsMap] || [];
  if (!isNil(deleteConflictsMapForId) && deleteConflictsMapForId?.length > 0) {
    modalBody = (
      <div className="text-xs">
        <div>
          <FormattedMessage
            defaultMessage='You cannot delete "{name}" because it is assigned to the user(s) listed below. Please remove all assignees in order to delete "{name}".'
            id="Siyr1z"
            description="User listing of users with role being deleted, which block deletion"
            values={{ name }}
          />
        </div>
        <Table data={deleteConflictsMapForId} closeModal={closeModal} />
      </div>
    );
  }

  const queryClient = useQueryClient();

  const { mutate: deleteRole } = useMutation({
    mutationFn: (id: string) => deleteUserRole(id),
    onSuccess: () => {
      toast(
        intl.formatMessage({
          defaultMessage: "Role deleted successfully.",
          id: "BSrCYO",
          description: "Role deleted success message",
        }),
        { appearance: "success" },
      );
      queryClient.invalidateQueries({ queryKey: rolesKeys.userRole() });
    },
  });

  const onDelete = useCallback(() => {
    deleteRole(id);

    closeModal();
  }, [closeModal, deleteRole, id]);

  return (
    <Modal onClose={closeModal} isOpen={isOpen} size="sm">
      <ModalHeader
        enableClose
        title={
          <FormattedMessage
            defaultMessage="Delete Role"
            id="S7BncY"
            description="delete role modal header"
          />
        }
      />
      <ModalBody overflow>{modalBody}</ModalBody>
      <ModalFooter>
        <Button onClick={closeModal}>
          <FormattedMessage
            defaultMessage="Cancel"
            id="uT5L4h"
            description="cancel button label"
          />
        </Button>
        {!deleteConflictsMap[id]?.length && (
          <Button appearance="primary" onClick={onDelete}>
            <FormattedMessage
              defaultMessage="Confirm"
              id="hCya45"
              description="confirm button label"
            />
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export function useDeleteModal() {
  return useModal(DeleteModal);
}
