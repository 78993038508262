import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { useMutation } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEmpty } from "remeda";
import {
  createDynamicIAMRoleCft,
  type SecurityCapabilitiesType,
} from "requests";
import { useToastActions } from "stores";
import { Bold } from "ui";
import { regexes } from "utils";
import { useWizardContext } from "../../../../../../../components/Wizard";
import {
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  AGENTLESS_SCAN,
  AWS,
  AWS_PARTITION,
  CREATE_IAM_ROLE_CFT,
  DOWNLOAD_IAM_ROLE_CFT,
  FEATURES,
  ORGANIZATION,
  REMEDIATION,
} from "../../../../../../constants";
import { useGetSupportedFeaturesList } from "../../../../../../hooks";
import { type AwsGetStartedStepValuesType } from "../../../../../../types";
import { parseErrorsFromResponse } from "../../../../../../utils";
import InstructionsSidecar from "../../../../components/InstructionsSidecar";
import ConfigHelpers from "./ConfigHelpers";
import { getInstructions } from "./instructions";
import { downloadDynamicIAMRoleCft, redirectUrl } from "./utils";

type CreateOrDownloadIAMRoleCftProps = {
  closeModal: () => void;
  isEdit: boolean;
  setCftDownloadedOrCreated: (val: boolean) => void;
};

export default function CreateOrDownloadIAMRoleCft({
  closeModal,
  isEdit,
  setCftDownloadedOrCreated,
}: CreateOrDownloadIAMRoleCftProps) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const {
    state: { steps },
  } = useWizardContext();
  const { setValue, watch } = useFormContext();
  const { cloudScanMode } = useFlags();

  const [isOpenConfigHelper, setIsOpenConfigHelper] = useState(false);
  const [showCreateIAMRoleSteps, setShowCreateIAMRoleSteps] = useState(false);
  const [isIAMRoleCftDownloaded, setIAMRoleCftDownloaded] = useState(false);
  const [iamRoleCftMode, setIAMRoleCftMode] = useState("");

  const {
    accountType = "account",
    partitionType: awsPartition,
    securityCapabilities,
    mode = "",
  } = steps[0]?.values as AwsGetStartedStepValuesType;

  const isGovcloudSelected = awsPartition.includes("gov");

  const { supportedFeaturesList } = useGetSupportedFeaturesList({
    cloudType: AWS,
    payload: {
      accountType,
      awsPartition,
    },
  });

  const isOrg = accountType === ORGANIZATION;
  const features = Object.keys(securityCapabilities)
    .filter((key) => securityCapabilities[key as SecurityCapabilitiesType])
    .filter((feature) =>
      supportedFeaturesList.includes(feature as SecurityCapabilitiesType),
    );

  const accountId = watch("accountId");
  const remediation = watch("remediation");
  const customMemberRoleNameEnabled = watch("customMemberRoleNameEnabled");
  const useTenantExternalId = watch("useTenantExternalId");

  const disabled = useMemo(
    () => (isEmpty(accountId) ? true : !regexes.awsAccountId.test(accountId)),
    [accountId],
  );

  const instructions = useMemo(
    () =>
      getInstructions({
        intl,
        isEdit,
        isOrg,
        showCreateIAMRoleSteps,
      }),
    [intl, isEdit, isOrg, showCreateIAMRoleSteps],
  );

  const featuresList = remediation ? [...features, REMEDIATION] : features;

  const featureWithModes = featuresList.map((feature) => {
    if (feature === AGENTLESS_SCAN) {
      return {
        feature,
        mode,
      };
    }
    return { feature };
  });

  const payload = {
    [ACCOUNT_ID]: accountId,
    [ACCOUNT_TYPE]: accountType,
    [AWS_PARTITION]: awsPartition,
    [FEATURES]: featuresList,
    ...(cloudScanMode && !isGovcloudSelected && { featureWithModes }),
    customMemberRoleNameEnabled,
    useTenantExternalId,
  };

  const { mutateAsync: downloadIAMRoleCft, isPending: isDownloadingCft } =
    useMutation({
      mutationFn: async () => {
        try {
          setIAMRoleCftMode(DOWNLOAD_IAM_ROLE_CFT);

          const response = await downloadDynamicIAMRoleCft(payload);

          const externalId = response["external-id"];
          setValue("externalId", externalId);
          setIAMRoleCftDownloaded(true);
          setCftDownloadedOrCreated(true);
        } catch (err: unknown) {
          toast(parseErrorsFromResponse(intl, err), {
            appearance: "error",
          });
          closeModal();
        }
      },
    });

  const { mutateAsync: createIAMRoleCft, isPending: isCreatingCft } =
    useMutation({
      mutationFn: async () => {
        try {
          setIAMRoleCftMode(CREATE_IAM_ROLE_CFT);

          const response = await createDynamicIAMRoleCft(payload);

          const externalId = response.externalId;
          setValue("externalId", externalId);
          setIAMRoleCftDownloaded(true);
          setCftDownloadedOrCreated(true);
          redirectUrl(response.createStackLinkWithS3PresignedUrl);
        } catch (err: unknown) {
          toast(parseErrorsFromResponse(intl, err), {
            appearance: "error",
          });
          closeModal();
        }
      },
    });

  const title = showCreateIAMRoleSteps
    ? intl.formatMessage({
        defaultMessage: "Create IAM Role",
        id: "G8Q1Vk",
        description: "Sidecar instruction heading",
      })
    : intl.formatMessage({
        defaultMessage: "Download IAM Role CFT",
        id: "ALBMzs",
        description: "Sidecar instruction heading",
      });

  return (
    <section>
      <Bold addClassName="flex items-center">
        <FormattedMessage
          defaultMessage="Apply permissions for cloud account"
          id="Kf+11A"
          description="description for create and download stack link"
        />
      </Bold>

      <ConfigHelpers
        createIAMRoleCft={createIAMRoleCft}
        downloadIAMRoleCft={downloadIAMRoleCft}
        disabled={disabled}
        iamRoleCftMode={iamRoleCftMode}
        isIAMRoleCftDownloaded={isIAMRoleCftDownloaded}
        isDownloadingCft={isDownloadingCft}
        isCreatingCft={isCreatingCft}
        isEdit={isEdit}
        setIsOpenConfigHelper={setIsOpenConfigHelper}
        setShowCreateIAMRoleSteps={setShowCreateIAMRoleSteps}
      />
      <InstructionsSidecar
        instructions={instructions}
        isOpen={isOpenConfigHelper}
        setOpen={setIsOpenConfigHelper}
        title={title}
      />
    </section>
  );
}
