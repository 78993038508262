import {
  useCallback,
  type CSSProperties,
  type DetailedHTMLProps,
  type HTMLAttributes,
  type ReactElement,
  type Ref,
} from "react";
import { useIntl } from "react-intl";
import { classNames } from "utils";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { type ColumnInstance, type Data } from "../../types";
import { type TableLayoutProps } from "../TableLayout";
import { DragHandle } from "./DragHandle";
import { SortButton } from "./SortButton";

export interface HeaderCellProps<D extends Data> {
  column: ColumnInstance<D>;
  orderable?: boolean;
  lastCell?: boolean;
  lastGroup?: boolean;
  style?: CSSProperties;
  sortRef?: Ref<HTMLDivElement>;
  dragHandleProps?: Record<string, unknown>;
  isDragging?: boolean;
  isHeld?: boolean;
}

export function HeaderCell<D extends Data>({
  appearance = "primary",
  column,
  orderable = false,
  lastCell = false,
  lastGroup = false,
  sortRef,
  style = {},
  dragHandleProps,
  isDragging,
  isHeld,
}: HeaderCellProps<D> & Pick<TableLayoutProps, "appearance">): ReactElement {
  const resizerProps = column.canResize ? column.getResizerProps() : null;
  const intl = useIntl();

  /* c8 ignore next */
  const onMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (resizerProps) {
        const { onMouseDown } = resizerProps as {
          onMouseDown: (e: React.MouseEvent) => void;
        };
        e.preventDefault();
        onMouseDown(e);
      }
    },
    [resizerProps],
  );

  const sortDirection = column.isSortedDesc
    ? "descending"
    : column.isSorted
      ? "ascending"
      : "none";

  const className = classNames(
    "h-full bg-white px-4 dark:bg-blue-steel-950",
    lastCell && "grow",
    appearance === "primary" &&
      "border-b border-r py-2 font-bold dark:border-blue-steel-850",
    appearance === "primary" && lastGroup && "last:border-r-0",
    appearance === "secondary" && "text-secondary",
    isDragging && "opacity-50",
    appearance === "card" && "font-bold",
    appearance === "card" &&
      !lastCell &&
      "relative after:absolute after:inset-y-2 after:right-0 after:block after:border after:bg-blue-300 after:dark:border-blue-steel-850",
  );

  const headerClassNames = classNames(
    "flex h-full  justify-between text-xs leading-tight",
    appearance === "card" && "items-center",
  );
  const { key, ...headerProps } = column.getHeaderProps();
  return (
    <div
      aria-sort={sortDirection}
      data-table-column-id={column.id}
      data-testid={`table-header-column-${column.id}`}
      key={key}
      {...headerProps}
      style={
        { ...style, ...headerProps.style } as DetailedHTMLProps<
          HTMLAttributes<HTMLDivElement>,
          HTMLDivElement
        >
      }
      className={className}
      ref={sortRef}
    >
      <div className={headerClassNames}>
        <span className="flex space-x-1">
          <span className="flex-auto truncate">{column.render("Header")}</span>
          <SortButton column={column} />
        </span>
        {orderable && (
          <span>
            <DragHandle
              {...dragHandleProps}
              label={intl.formatMessage({
                defaultMessage: "Reorder column",
                id: "L2ECbY",

                description: "Drag icon user can click on to reorder",
              })}
              isHeld={isHeld}
              iconSize="xs"
              direction="horizontal"
            />
          </span>
        )}
      </div>
      {resizerProps && !lastCell && (
        <div
          {...resizerProps}
          onMouseDown={onMouseDown}
          className="absolute right-0 top-0 h-full w-2 "
        />
      )}
    </div>
  );
}

export function SortableHeaderCell<D extends Data>(
  props: HeaderCellProps<D> & Pick<TableLayoutProps, "appearance">,
): ReactElement {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props.column.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <HeaderCell
      {...props}
      style={style}
      sortRef={setNodeRef}
      isDragging={isDragging}
      dragHandleProps={{ ...attributes, ...listeners }}
    />
  );
}
