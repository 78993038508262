import { useState, type ReactElement } from "react";
import { Portal } from "ui";
import { classNames } from "utils";

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragStartEvent,
} from "@dnd-kit/core";
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import { type ColumnInstance, type Data, type HeaderGroup } from "../../types";
import { useTableLayoutEnabled, type TableLayoutProps } from "../TableLayout";
import { HeaderCell, SortableHeaderCell } from "./HeaderCell";
import { StickyBorder } from "./StickyBorder";

export interface HeaderRowProps<D extends Data> {
  disableColumnOrder?: boolean;
  headerGroup: HeaderGroup<D>;
  setColumnOrder: (columnIds: string[]) => void;
}

export type GroupedColumns<D extends Data> = {
  sortRowColumn: ColumnInstance<D>[];
  selectRowColumn: ColumnInstance<D>[];
  groupedColumns: ColumnInstance<D>[];
  generalColumns: ColumnInstance<D>[];
  leftStickyColumns: ColumnInstance<D>[];
  rightStickyColumns: ColumnInstance<D>[];
};

/**
 * Group the columns into the sections the will be rendered.
 */
export function groupColumns<D extends Data>(
  columns: HeaderGroup<D>[],
): GroupedColumns<D> {
  return columns.reduce(
    (accum: GroupedColumns<D>, column) => {
      if (column.isSortRowColumn) {
        accum.sortRowColumn.push(column);
      } else if (column.isSelectRowColumn) {
        accum.selectRowColumn.push(column);
      } else if (column.isGrouped) {
        accum.groupedColumns.push(column);
      } else if (column.sticky) {
        if (column.stickyPosition === "left") {
          accum.leftStickyColumns.push(column);
        } else {
          accum.rightStickyColumns.push(column);
        }
      } else {
        accum.generalColumns.push(column);
      }
      return accum;
    },
    {
      sortRowColumn: [],
      selectRowColumn: [],
      groupedColumns: [],
      generalColumns: [],
      leftStickyColumns: [],
      rightStickyColumns: [],
    },
  );
}

export function HeaderRow<D extends Data>({
  appearance = "primary",
  disableColumnOrder = false,
  headerGroup,
  setColumnOrder,
}: HeaderRowProps<D> & Pick<TableLayoutProps, "appearance">): ReactElement {
  const {
    sortRowColumn,
    selectRowColumn,
    groupedColumns,
    leftStickyColumns,
    generalColumns,
    rightStickyColumns,
  } = groupColumns(headerGroup.headers);
  const tableLayoutEnabled = useTableLayoutEnabled();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [activeId, setActiveId] = useState<string | null>(null);

  const width = tableLayoutEnabled ? { width: undefined } : {};

  const className = classNames(
    "box-content",
    appearance === "primary" &&
      !tableLayoutEnabled &&
      "border-l border-t dark:border-blue-steel-850",
    appearance === "card" && "mx-2",
  );

  /* c8 ignore next */
  const activeColumn =
    activeId && generalColumns.find((row) => row.id === activeId);

  /* c8 ignore next */
  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    setActiveId(active.id);
  }

  /* c8 ignore next */
  function handleSortEnd(event: DragEndEvent) {
    const { active, over } = event;
    setActiveId(null);
    if (active.id === over?.id) return;

    const oldIndex = generalColumns.findIndex(
      (row) => String(row.id) === active.id,
    );
    const newIndex = generalColumns.findIndex(
      (row) => String(row.id) === over?.id,
    );

    const next = groupedColumns
      .map((c) => c.id)
      .concat(leftStickyColumns.map((c) => c.id))
      .concat(
        arrayMove(
          generalColumns.map((c) => c.id),
          oldIndex,
          newIndex,
        ),
      )
      .concat(rightStickyColumns.map((c) => c.id));

    setColumnOrder(next);
  }

  const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps({
    style: {
      minHeight:
        appearance === "card"
          ? "2.5rem"
          : appearance === "primary"
            ? "2rem"
            : "1rem",
      marginBottom: tableLayoutEnabled ? "-1px" : "",
      ...width,
    },
  });

  return (
    <div key={key} {...headerGroupProps} className={className}>
      {!!sortRowColumn.length && (
        <div className="flex">
          {sortRowColumn.map((column) => (
            <HeaderCell
              appearance={appearance}
              column={column}
              key={column.id}
            />
          ))}
        </div>
      )}
      {!!selectRowColumn.length && (
        <div className="flex">
          {selectRowColumn.map((column) => (
            <HeaderCell
              appearance={appearance}
              column={column}
              key={column.id}
            />
          ))}
        </div>
      )}
      {!!groupedColumns.length && (
        <div className="flex">
          {groupedColumns.map((column) => (
            <HeaderCell
              appearance={appearance}
              column={column}
              key={column.id}
            />
          ))}
        </div>
      )}
      {!!leftStickyColumns.length && (
        <div className="flex">
          {leftStickyColumns.map((column) => (
            <HeaderCell
              appearance={appearance}
              column={column}
              key={column.id}
            />
          ))}
        </div>
      )}
      <div className="flex grow">
        <DndContext
          autoScroll={{ threshold: { x: 0.2, y: 0 } }}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleSortEnd}
          modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
        >
          <SortableContext
            items={generalColumns.map((column) => column.id)}
            strategy={horizontalListSortingStrategy}
          >
            {generalColumns.map((column, index) => (
              <SortableHeaderCell
                appearance={appearance}
                column={column}
                key={column.id}
                orderable={!disableColumnOrder}
                lastCell={generalColumns.length === index + 1}
              />
            ))}
          </SortableContext>
          <Portal>
            <DragOverlay zIndex={20}>
              {activeColumn ? (
                <HeaderCell
                  appearance={appearance}
                  column={activeColumn}
                  key={activeColumn.id}
                  orderable={!disableColumnOrder}
                  isHeld
                />
              ) : null}
            </DragOverlay>
          </Portal>
        </DndContext>
      </div>
      {!!rightStickyColumns.length && (
        <div
          className="flex"
          style={{
            background: "inherit",
            position: "sticky",
            right: 0,
          }}
        >
          <StickyBorder />
          {rightStickyColumns.map((column, index) => (
            <HeaderCell
              appearance={appearance}
              column={column}
              key={column.id}
              lastGroup={tableLayoutEnabled}
              lastCell={rightStickyColumns.length === index + 1}
            />
          ))}
        </div>
      )}
    </div>
  );
}
