import {
  NextButton,
  PrevButton,
  WizardForm,
  useWizardContext,
} from "../../../../../components/Wizard";

import { FormLayout } from "form";
import { useState } from "react";
import { useIntl } from "react-intl";
import { type AwsConfigureAccountStepValuesType } from "../../../../types";
import { ProductDocumentationLink } from "../../components";
import { useAWSSelectMemberAccountsContext } from "../context/AwsSelectMemberAccountsContext";
import { handleSubmitAWSOrgUnitSelection } from "../context/AwsSelectMemberAccountsContext/state/actions";
import { getAwsOUSelectProjectsIsLoading } from "../context/AwsSelectMemberAccountsContext/state/getters";
import ConfigureAccountDetails from "./ConfigureAccountDetails";

type ConfigureAccountProps = {
  context: {
    closeModal: () => void;
    isEdit: boolean;
    initUnifiedCftDisabled: boolean;
    accountName: string;
  };
};

export default function ConfigureAccount({
  context: { closeModal, isEdit, initUnifiedCftDisabled, accountName },
}: ConfigureAccountProps) {
  const intl = useIntl();
  const {
    state: { steps },
  } = useWizardContext();

  const { externalId } = steps[1]?.values as AwsConfigureAccountStepValuesType;
  const [isCftDownloadedOrCreated, setCftDownloadedOrCreated] = useState(
    () => !!externalId,
  );

  const {
    state: selectMemberAccountsState,
    dispatch: selectMemberAccountsDispatch,
  } = useAWSSelectMemberAccountsContext();

  const isSelectAccountsLoading = getAwsOUSelectProjectsIsLoading(
    selectMemberAccountsState,
  );

  const onNextBtnClick = () => {
    return handleSubmitAWSOrgUnitSelection(
      intl,
      selectMemberAccountsState,
      selectMemberAccountsDispatch,
    );
  };

  const [disabled, setDisabled] = useState(false);

  const isNextDisabled =
    isSelectAccountsLoading || !isCftDownloadedOrCreated || disabled;

  return (
    <WizardForm
      addClassName="[&>div:nth-child(2)]:relative"
      actions={
        <>
          <PrevButton />
          <NextButton disabled={isNextDisabled} validateStep={onNextBtnClick} />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <FormLayout>
        <ConfigureAccountDetails
          closeModal={closeModal}
          isEdit={isEdit}
          setCftDownloadedOrCreated={setCftDownloadedOrCreated}
          initUnifiedCftDisabled={initUnifiedCftDisabled}
          accountName={accountName}
          setDisabled={setDisabled}
        />
      </FormLayout>
    </WizardForm>
  );
}
