import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const DataSecurityIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 32 32"}>
      <path
        d="M0.25013 13.75V0.25H13.7501V13.75H0.25013ZM4.75013 4.75V9.25H9.25013V4.75H4.75013ZM18.2501 0.25H31.7501V13.75H18.2501V0.25ZM27.2501 9.25V4.75H22.7501V9.25H27.2501ZM0.25013 31.75V18.25H13.7501V31.75H0.25013ZM4.75013 22.75V27.25H9.25013V22.75H4.75013ZM29.5001 18.25H31.7501V27.25H25.0001V25H22.7501V31.75H18.2501V18.25H25.0001V20.5H29.5001V18.25ZM29.5001 29.5H31.7501V31.75H29.5001V29.5ZM25.0001 29.5H27.2501V31.75H25.0001V29.5Z"
        className="dark:fill-white"
      />
    </SvgIcon>
  );
};
