import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const ThumbsUpOutlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 24 24"}>
      <path d="M16.4996 5.20259C16.4996 2.76065 15.3595 1.00391 13.4932 1.00391C12.467 1.00391 12.1149 1.60527 11.747 3.00348C11.6719 3.29233 11.635 3.43297 11.596 3.57157C11.495 3.93031 11.3192 4.54106 11.069 5.40258C11.0623 5.42566 11.0524 5.44741 11.0396 5.46749L8.17281 9.95315C7.49476 11.0141 6.49429 11.8296 5.31841 12.2798L4.84513 12.461C3.5984 12.9384 2.87457 14.2421 3.1287 15.5527L3.53319 17.6388C3.77462 18.8839 4.71828 19.8748 5.9501 20.1767L13.5778 22.0462C16.109 22.6666 18.6674 21.1317 19.3113 18.6064L20.7262 13.0572C21.1697 11.3179 20.1192 9.54845 18.3799 9.10498C18.1175 9.03807 17.8478 9.00422 17.5769 9.00422H15.7536C16.2497 7.37133 16.4996 6.11155 16.4996 5.20259ZM4.60127 15.2672C4.48576 14.6715 4.81477 14.0788 5.38147 13.8619L5.85475 13.6806C7.33036 13.1157 8.58585 12.0923 9.43674 10.7609L12.3035 6.27526C12.3935 6.13437 12.4629 5.98131 12.5095 5.82074C12.7608 4.95574 12.9375 4.34175 13.0399 3.97786C13.083 3.82461 13.1239 3.66916 13.1976 3.38519C13.3875 2.66348 13.4809 2.50391 13.4932 2.50391C14.3609 2.50391 14.9996 3.48797 14.9996 5.20259C14.9996 6.08708 14.6738 7.53803 14.0158 9.51766C13.8544 10.0032 14.2158 10.5042 14.7275 10.5042H17.5769C17.7228 10.5042 17.868 10.5224 18.0093 10.5585C18.9459 10.7973 19.5115 11.7501 19.2727 12.6866L17.8578 18.2357C17.4172 19.9636 15.6668 21.0138 13.9349 20.5893L6.30718 18.7198C5.64389 18.5572 5.13577 18.0237 5.00577 17.3532L4.60127 15.2672Z" />
      {props.children}
    </SvgIcon>
  );
};
