import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AzureKubernetesServicesIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Azure Kubernetes Services",
    id: "bIz/IT",

    description: "Azure Kubernetes Services icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M46.6185 35.5162L34.3813 40.1905V20.4546L46.6185 24.7881V35.5162Z"
        className="fill-[#804998] dark:fill-white"
      />
      <path
        d="M23.2515 23.6999V36.662L32.4177 40.1489V20.4546L23.2515 23.6999ZM24.2564 35.7226L23.7571 35.5674V24.8345L24.2564 24.6937L24.7813 24.5464L25.3398 24.3896V36.0587L24.7813 35.8859L24.2564 35.7226ZM26.5544 36.4347L25.9271 36.2411V24.2232L26.5544 24.0472L27.2185 23.8599L27.929 23.6599V36.8604L27.2185 36.6396L26.5544 36.4347ZM29.4989 37.3453L28.6859 37.094V23.4471L29.4989 23.2182L30.3662 22.9734L31.304 22.7094V37.9054L30.3662 37.6157L29.4989 37.3453Z"
        className="fill-[#68217A] dark:fill-white"
      />
      <path
        d="M75.2627 35.5162L63.0239 40.1905V20.4546L75.2627 24.7881V35.5162Z"
        className="fill-[#804998] dark:fill-white"
      />
      <path
        d="M51.8965 23.6999V36.662L61.0628 40.1489V20.4546L51.8965 23.6999ZM52.8998 35.7226L52.4006 35.5674V24.8345L52.8998 24.6937L53.4247 24.5464L53.9832 24.3896V36.0587L53.4247 35.8859L52.8998 35.7226ZM55.1978 36.4347L54.5705 36.2411V24.2232L55.1978 24.0472L55.8619 23.8599L56.5724 23.6599V36.8604L55.8619 36.6396L55.1978 36.4347ZM58.1423 37.3453L57.3294 37.094V23.4471L58.1423 23.2182L59.0096 22.9734L59.9474 22.7094V37.9054L59.0096 37.6157L58.1423 37.3453Z"
        className="fill-[#68217A] dark:fill-white"
      />
      <path
        d="M46.6185 74.8728L34.3813 79.5455V59.8096L46.6185 64.1447V74.8728Z"
        className="fill-[#804998] dark:fill-white"
      />
      <path
        d="M23.2515 63.0565V76.0186L32.4177 79.5055V59.8096L23.2515 63.0565ZM24.2564 75.0792L23.7571 74.924V64.1911L24.2564 64.0502L24.7813 63.903L25.3398 63.7462V75.4153L24.7813 75.2424L24.2564 75.0792ZM26.5544 75.7897L25.9271 75.5961V63.5798L26.5544 63.4021L27.2185 63.2149L27.929 63.0149V76.2154L27.2185 75.9946L26.5544 75.7897ZM29.4989 76.7019L28.6859 76.4506V62.8021L29.4989 62.5732L30.3662 62.3284L31.304 62.0643V77.2604L30.3662 76.9707L29.4989 76.7019Z"
        className="fill-[#68217A] dark:fill-white"
      />
      <path
        d="M75.2627 74.8728L63.0239 79.5455V59.8096L75.2627 64.1447V74.8728Z"
        className="fill-[#804998] dark:fill-white"
      />
      <path
        d="M51.8965 63.0565V76.0186L61.0628 79.5055V59.8096L51.8965 63.0565ZM52.8998 75.0792L52.4006 74.924V64.1911L52.8998 64.0502L53.4247 63.903L53.9832 63.7462V75.4153L53.4247 75.2424L52.8998 75.0792ZM55.1978 75.7897L54.5705 75.5961V63.5798L55.1978 63.4021L55.8619 63.2149L56.5724 63.0149V76.2154L55.8619 75.9946L55.1978 75.7897ZM58.1423 76.7019L57.3294 76.4506V62.8021L58.1423 62.5732L59.0096 62.3284L59.9474 62.0643V77.2604L59.0096 76.9707L58.1423 76.7019Z"
        className="fill-[#68217A] dark:fill-white"
      />
      <path
        d="M61.8633 55.1368L49.6245 59.8096V40.0752L61.8633 44.4087V55.1368Z"
        className="fill-[#804998] dark:fill-white"
      />
      <path
        d="M38.4961 43.3205V56.2826L47.6624 59.7695V40.0752L38.4961 43.3205ZM39.4995 55.3432L39.0002 55.188V44.4551L39.4995 44.3143L40.0243 44.167L40.5828 44.0102V55.6793L40.0243 55.5065L39.4995 55.3432ZM41.7974 56.0554L41.1701 55.8617V43.8454L41.7974 43.6694L42.4615 43.4821L43.172 43.2821V56.4826L42.4615 56.2618L41.7974 56.0554ZM44.7419 56.9659L43.929 56.7147V43.0677L44.7419 42.8388L45.6092 42.594L46.547 42.33V57.526L45.6092 57.2363L44.7419 56.9659Z"
        className="fill-[#68217A] dark:fill-white"
      />
      <path
        d="M33.3672 55.1368L21.1284 59.8096V40.0752L33.3672 44.4087V55.1368Z"
        className="fill-[#804998] dark:fill-white"
      />
      <path
        d="M10 43.3205V56.2826L19.1663 59.7695V40.0752L10 43.3205ZM11.0034 55.3432L10.5041 55.188V44.4551L11.0034 44.3143L11.5282 44.167L12.0867 44.0102V55.6793L11.5282 55.5065L11.0034 55.3432ZM13.3013 56.0554L12.674 55.8617V43.8454L13.3013 43.6694L13.9654 43.4821L14.6775 43.2789V56.4794L13.967 56.2586L13.3013 56.0554ZM16.2458 56.9659L15.4329 56.7147V43.0677L16.2458 42.8388L17.1131 42.594L18.0509 42.33V57.526L17.1131 57.2363L16.2458 56.9659Z"
        className="fill-[#68217A] dark:fill-white"
      />
      <path
        d="M90.0005 55.1368L77.7617 59.8096V40.0752L90.0005 44.4087V55.1368Z"
        className="fill-[#804998] dark:fill-white"
      />
      <path
        d="M66.6333 43.3205V56.2826L75.7996 59.7695V40.0752L66.6333 43.3205ZM67.6367 55.3432L67.1374 55.188V44.4551L67.6367 44.3143L68.1615 44.167L68.72 44.0102V55.6793L68.1615 55.5065L67.6367 55.3432ZM69.9346 56.0554L69.3073 55.8617V43.8454L69.9346 43.6694L70.5987 43.4821L71.3093 43.2821V56.4826L70.5987 56.2618L69.9346 56.0554ZM72.8791 56.9659L72.0662 56.7147V43.0677L72.8791 42.8388L73.7464 42.594L74.6842 42.33V57.526L73.7464 57.2363L72.8791 56.9659Z"
        className="fill-[#68217A] dark:fill-white"
      />
    </SvgIcon>
  );
};
