import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const ThumbsDownOutlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 24 24"}>
      <path d="M16.4996 17.9852C16.4996 20.4271 15.3595 22.1838 13.4932 22.1838C12.5183 22.1838 12.1518 21.6411 11.8021 20.3881L11.596 19.6162C11.495 19.2574 11.3192 18.6467 11.069 17.7852C11.0623 17.7621 11.0524 17.7403 11.0396 17.7203L8.17281 13.2346C7.49476 12.1736 6.49429 11.3581 5.31841 10.9079L4.84513 10.7267C3.5984 10.2494 2.87457 8.94562 3.1287 7.63505L3.53319 5.54897C3.77462 4.30388 4.71828 3.31298 5.9501 3.01106L13.5778 1.14153C16.109 0.521138 18.6674 2.05607 19.3113 4.5814L20.7262 10.1306C21.1697 11.8698 20.1192 13.6393 18.3799 14.0828C18.1175 14.1497 17.8478 14.1835 17.5769 14.1835H15.7536C16.2497 15.8164 16.4996 17.0762 16.4996 17.9852ZM4.60127 7.92059C4.48576 8.5163 4.81477 9.10893 5.38147 9.3259L5.85475 9.5071C7.33036 10.0721 8.58585 11.0954 9.43674 12.4268L12.3035 16.9125C12.3935 17.0534 12.4629 17.2064 12.5095 17.367L13.0614 19.2873L13.2731 20.0786C13.4125 20.5666 13.4827 20.6838 13.4932 20.6838C14.3609 20.6838 14.9996 19.6998 14.9996 17.9852C14.9996 17.1007 14.6738 15.6497 14.0158 13.6701C13.8544 13.1846 14.2158 12.6835 14.7275 12.6835H17.5769C17.7228 12.6835 17.868 12.6653 18.0093 12.6293C18.9459 12.3905 19.5115 11.4377 19.2727 10.5012L17.8578 4.952C17.4172 3.22415 15.6668 2.17393 13.9349 2.59841L6.30718 4.46794C5.64389 4.63051 5.13577 5.16407 5.00577 5.83451L4.60127 7.92059Z" />
      {props.children}
    </SvgIcon>
  );
};
