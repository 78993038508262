import { z } from "zod";

export const AzureSelectMonitoredProjectsChildrenRequestSchema = z.object({
  clientId: z.string(),
  cloudAccount: z.object({
    accountId: z.string(),
    accountType: z.string(),
    enabled: z.boolean(),
    name: z.string(),
  }),
  environmentType: z.string(),
  key: z.string().optional(),
  monitorFlowLogs: z.boolean(),
  rootSyncEnabled: z.boolean(),
  servicePrincipalId: z.string(),
  tenantId: z.string(),
  authMode: z.string().optional(),
});

const HierarchySelection = z.object({
  id: z.string(),
  type: z.string(),
  displayName: z.string(),
  parent: z.string().optional(),
  hasChild: z.boolean(),
});

export const AzureSelectMonitoredProjectsChildrenResponseSchema =
  z.array(HierarchySelection);

export const AzureSelectMonitoredProjectsAncestorsRequestSchema = z.object({
  resourceIds: z.array(z.string()),
});

export const AzureSelectMonitoredProjectsAncestorsResponseSchema = z.array(
  z.object({
    resourceId: z.string(),
    ancestors: z.array(HierarchySelection),
    status: z.string(),
    responseCode: z.number(),
  }),
);

export type AzureSelectMonitoredProjectsAncestorsType = z.infer<
  typeof AzureSelectMonitoredProjectsAncestorsResponseSchema
>;
