import { CloudTypeIcon, DataSecurityIcon } from "icons";
import { FormattedMessage, useIntl } from "react-intl";
import { useToastActions } from "stores";
import {
  Body,
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  Title,
  useModal,
  type RenderModalProps,
} from "ui";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { enableSecurity, tenantKeys } from "requests";
import { useDlpStatus } from "../../../../utils/useDlpStatus";
import CloudSelection from "../../../settings/cloudAccounts/CloudOnboarding/CloudSelection";
import { settingsCloudAccountsRoute } from "../../../settings/cloudAccounts/settingsCloudAccountsRoute";
import CloudCard from "./CloudCard";
import freemium_bg from "./freemium_bg.webp";

function FreemiumModal({
  isOpen,
  closeModal,
}: RenderModalProps & { status?: string }) {
  const navigate = useNavigate();
  const { toast } = useToastActions();
  const intl = useIntl();

  const [cloudAccount, setCloudAccount] = useState("new account");
  const [cloudType, setCloudType] = useState("AWS");
  const { openModal } = useModal(CloudSelection);
  const { data } = useDlpStatus();
  const queryClient = useQueryClient();

  const { mutateAsync: provision, isPending } = useMutation({
    mutationFn: async () => {
      try {
        await enableSecurity();
        queryClient.invalidateQueries({ queryKey: tenantKeys.dlpStatus() });
        toast(
          intl.formatMessage({
            defaultMessage:
              "Your tenant provisioning has started. Please wait for some time for the tenant to be provisioned.",
            id: "/T11Xf",
            description: "Message when tenant provisioning is successful",
          }),
          { appearance: "success" },
        );
        closeModal();
        if (cloudAccount === "existing account") {
          navigate({
            to: settingsCloudAccountsRoute.id,
          });
        } else {
          openModal({ fromFreemium: true });
        }
      } catch (error) {
        toast(
          intl.formatMessage({
            defaultMessage: "Your tenant failed to provision",
            id: "24Fezr",
            description: "Message when tenant provisioning has failed",
          }),
          { appearance: "warning" },
        );
      }
    },
  });

  return (
    <Modal
      onClose={() => {
        closeModal();
      }}
      isOpen={isOpen}
      size="md"
      eventsToClose={{
        enableClickOutside: false,
      }}
    >
      <ModalHeader enableClose enableFullScreen title="" />
      <ModalBody addClassName="p-6">
        <img
          width={320}
          height={200}
          src={freemium_bg}
          className="absolute left-0 top-[33px] -z-10"
        />
        <Card addClassName="opacity-80">
          <CardBody addClassName="flex">
            <Body
              as="div"
              addClassName="h-fit bg-gray-100 p-2.5 dark:bg-blue-steel-950"
            >
              <DataSecurityIcon size="xxl" />
            </Body>
            <Body as="div" addClassName="pl-2 pt-1">
              <Title level={3}>
                <FormattedMessage
                  defaultMessage="Enable Data Security"
                  id="RyYrWB"
                  description="Data Security header"
                />
              </Title>
              <FormattedMessage
                defaultMessage="The Data Security capabilities on Prisma Cloud enable you to discover and classify data stored in AWS S3 buckets and Azure Blob storage and protect accidental exposure, misuse, or sharing of sensitive data."
                id="ikr2Nm"
                description="Data Security details"
              />
            </Body>
          </CardBody>
        </Card>
        <Body addClassName="p-4">
          <FormattedMessage
            defaultMessage="Select to onboard a new cloud account or select existing."
            id="efUgEx"
            description="Data Security enable options text"
          />
        </Body>
        <RadioGroup
          name="dataSecurity"
          value={cloudAccount}
          onChange={(event) => {
            setCloudAccount(event.target.value);
          }}
        >
          <Card>
            <CardBody>
              <Radio value="new account">
                <FormattedMessage
                  defaultMessage="Onboard a new cloud account and enable data security."
                  id="I+6enk"
                  description="Radio button option"
                />
              </Radio>
              <Body as="div" addClassName="flex gap-x-2">
                <CloudCard
                  onChange={setCloudType}
                  isSelected={cloudType === "AWS"}
                  icon={<CloudTypeIcon type="aws" size="3xl" />}
                  label="AWS"
                  disabled={cloudAccount !== "new account"}
                  content="Remediate issues and gain visibility in to your adherence to compliance standards."
                />
                <CloudCard
                  onChange={setCloudType}
                  isSelected={cloudType === "AZURE"}
                  icon={<CloudTypeIcon type="azure" size="3xl" />}
                  label="AZURE"
                  disabled={cloudAccount !== "new account"}
                  content="Monitor and identify compliance violations on your Azure environments."
                />
              </Body>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Radio value="existing account">
                <FormattedMessage
                  defaultMessage="Select an existing account and enable data security."
                  id="/8WQrO"
                  description="Radio button option"
                />
              </Radio>
            </CardBody>
          </Card>
        </RadioGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal}>
          <FormattedMessage
            defaultMessage="Dismiss"
            id="heUZX9"
            description="Dismiss button"
          />
        </Button>
        <Button
          isLoading={isPending}
          onClick={() => {
            provision();
          }}
          appearance="primary"
          disabled={data?.status !== "provisionNotStarted"}
        >
          <FormattedMessage
            defaultMessage="Enable Data Security"
            id="g3XV3c"
            description="Enable Data Security button"
          />
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export function useFreemiumModal() {
  return useModal(FreemiumModal);
}
