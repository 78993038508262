import { createRoute } from "@tanstack/react-router";
import { lazy } from "react";
import { alertsSavedViews } from "saved-views-next";
import { z } from "zod";
import {
  alertsLayoutRoute,
  AlertsLayoutSearchSchema,
} from "./alertsLayoutRoute";

export const Overview = lazy(() => import("./AlertsOverview"));

export const AlertsOverviewSearchSchema = AlertsLayoutSearchSchema.merge(
  z.object({
    groupBy: z.string().optional(),
    sortBy: z.string().optional(),
    sortOrder: z.string().optional(),
    displayFormat: z.string().optional(),
    alarmIds: z.number().optional(),
  }),
);

export type AlertsOverviewSearchType = z.infer<
  typeof AlertsOverviewSearchSchema
>;

export const alertsOverviewRoute = createRoute({
  getParentRoute: () => alertsLayoutRoute,
  path: "overview",
  component: Overview,
  validateSearch: AlertsOverviewSearchSchema,
  loaderDeps: ({ search: { viewId } }) => ({ viewId }),
  loader: ({ context, deps: { viewId } }) => {
    context?.queryClient.prefetchQuery(
      alertsSavedViews.getViewQueryOptions(viewId ?? "default"),
    );
  },
});
