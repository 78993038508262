import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { Body, Bold } from "ui";

import { isEmpty } from "remeda";
import { type SecurityCapabilitiesType } from "requests";
import { regexes } from "utils";
import { useWizardContext } from "../../../../../../../components/Wizard";
import DownloadTerraformScript from "../../../../../../components/DownloadTerraformScript";
import {
  ACCOUNT,
  ACCOUNT_TYPE,
  ACTIVE_DIRECTORY,
  AUTHENTICATION_MODE,
  AZURE,
  AZURE_GOV,
  DEPLOYMENT_TYPE,
  FEATURES,
  REMEDIATION,
  ROOT_SYNC_ENABLED,
  SUBSCRIPTION_ID,
  TENANT,
  TENANT_ID,
} from "../../../../../../constants";
import { useGetSupportedFeaturesList } from "../../../../../../hooks";
import { type AzureGetStartedStepValuesType } from "../../../../../../types";
import InstructionsSidecar from "../../../../components/InstructionsSidecar";
import { getInstructions } from "./instructions";

export default function AzureDownloadTerraform() {
  const { getValues } = useFormContext();
  const intl = useIntl();
  const {
    state: { steps },
  } = useWizardContext();
  const { tenantId, subscriptionId, remediation } = getValues();

  const {
    accountType,
    environmentType: deploymentType,
    securityCapabilities,
  } = (steps[0]?.values ?? {}) as AzureGetStartedStepValuesType;
  const isActiveDirectory = accountType === ACTIVE_DIRECTORY;

  const { supportedFeaturesList } = useGetSupportedFeaturesList({
    cloudType: AZURE,
    payload: {
      ...(isActiveDirectory ? { accountType: TENANT } : { accountType }),
      deploymentType,
      ...(accountType === TENANT && { rootSyncEnabled: true }),
    },
  });
  const { watch } = useFormContext();
  const authenticationMode = watch("authenticationMode");

  const [isOpenConfigHelper, setIsOpenConfigHelper] = useState(false);

  const isAzureActiveDirSelected = accountType === ACTIVE_DIRECTORY;
  const isGov = deploymentType === AZURE_GOV;

  const features = Object.keys(securityCapabilities)
    .filter((key) => securityCapabilities[key as SecurityCapabilitiesType])
    .filter((feature) =>
      supportedFeaturesList.includes(feature as SecurityCapabilitiesType),
    );

  const dynamicTerraformScriptPayload = useMemo(() => {
    let payload: Record<string, unknown> = {
      [ACCOUNT_TYPE]: isAzureActiveDirSelected ? TENANT : accountType,
      [TENANT_ID]: tenantId,
      [FEATURES]: remediation ? [...features, REMEDIATION] : features,
      [DEPLOYMENT_TYPE]: deploymentType,
      [AUTHENTICATION_MODE]: authenticationMode,
      ...(accountType === TENANT && { rootSyncEnabled: true }),
    };

    if (accountType === ACCOUNT) {
      payload = {
        ...payload,
        [SUBSCRIPTION_ID]: subscriptionId,
      };
    } else if (accountType === TENANT) {
      payload = {
        ...payload,
        [ROOT_SYNC_ENABLED]: true,
      };
    }

    let isDownloadDisabled = isEmpty(tenantId) || !regexes.guid.test(tenantId);
    if (accountType === ACCOUNT && !isDownloadDisabled) {
      isDownloadDisabled =
        isDownloadDisabled ||
        isEmpty(subscriptionId) ||
        !regexes.guid.test(subscriptionId);
    }

    return {
      fileName: `prisma-cloud-${AZURE}-terraform-${Math.floor(
        Date.now() / 1000,
      )}.tf.json`,
      isDownloadDisabled,
      endPoint: "/cas/v1/azure_template",
      payload,
    };
  }, [
    accountType,
    deploymentType,
    features,
    isAzureActiveDirSelected,
    remediation,
    subscriptionId,
    tenantId,
    authenticationMode,
  ]);
  const instructions = useMemo(
    () =>
      getInstructions({
        isAzureActiveDirSelected: [ACTIVE_DIRECTORY, TENANT].includes(
          accountType,
        ),
        isGov,
      }),
    [accountType, isGov],
  );

  return (
    <div>
      <div className="mb-2">
        <Bold size="sm">
          <FormattedMessage
            defaultMessage="Download the terraform and complete the steps to configure account."
            id="4msnrp"
            description="Instructions for downloading the terraform button"
          />
        </Bold>
      </div>
      <DownloadTerraformScript
        method={"post"}
        {...dynamicTerraformScriptPayload}
      />

      <Body size="sm" as="div" addClassName="mt-1">
        <FormattedMessage
          defaultMessage="<a>View steps</a> for more information."
          id="z7fQSR"
          description="Open help section"
          values={{
            a: (chunks) => (
              <InstructionsSidecar
                className="inline-flex"
                instructions={instructions}
                isOpen={isOpenConfigHelper}
                setOpen={setIsOpenConfigHelper}
                title={intl.formatMessage({
                  defaultMessage: "Download Terraform Script",
                  id: "QJ4RB4",
                  description: "Title for Download the Terraform script",
                })}
              >
                {chunks}
              </InstructionsSidecar>
            ),
          }}
        />
      </Body>
    </div>
  );
}
