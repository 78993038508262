import { createRoute } from "@tanstack/react-router";
import { lazy } from "react";
import { alertsLayoutRoute } from "./../alertsLayoutRoute";

export const AlertsL2Table = lazy(() => import("./AlertsL2Table"));

export const alertsL2TableRoute = createRoute({
  getParentRoute: () => alertsLayoutRoute,
  path: "$policyId",
  component: AlertsL2Table,
});
