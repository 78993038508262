import { useFormContext, useWatch } from "form";
import { FormattedMessage } from "react-intl";
import { Subtitle, Title } from "ui";
import { useWizardContext } from "../../../../../components/Wizard";
import { ACCOUNT, AWS, REMEDIATION } from "../../../../constants";
import {
  type AwsConfigureAccountStepValuesType,
  type AwsGetStartedStepValuesType,
} from "../../../../types";
import {
  setAccountId,
  setAccountName,
  setMemberRoleName,
  setRoleArn,
} from "../context/AwsSelectMemberAccountsContext/state/actions";

import { useQuery } from "@tanstack/react-query";
import { AccountGroupSelect } from "prisma";
import { useEffect, useMemo } from "react";
import { isEmpty } from "remeda";
import { accountNameExists, cloudAccountsKeys } from "requests";
import { useGetSupportedFeaturesList } from "../../../../hooks";
import AccountId from "../../components/AccountId";
import AccountName from "../../components/AccountName";
import ExternalId from "../../components/ExternalId";
import MemberRoleName from "../../components/MemberRoleName";
import RemediationCard from "../../components/RemediationCard";
import RoleArn from "../../components/RoleArn";
import { accountGroupsMultiSelect } from "../../utils";
import { useAWSSelectMemberAccountsContext } from "../context/AwsSelectMemberAccountsContext";
import CreateOrDownloadIAMRoleCft from "./AccountDetails/CreateOrDownloadIAMRoleCft";
import SelectMemberAccounts from "./AccountDetails/SelectMemberAccounts";
import AdvancedConfiguration from "./AdvancedConfiguration";
import ManagementOrMemberIAMRoleCft from "./ManagementOrMemberIAMRoleCft";

type ConfigureAccountDetailsProps = {
  closeModal: () => void;
  setDisabled: (val: boolean) => void;
  accountName: string;
  isEdit: boolean;
  initUnifiedCftDisabled: boolean;
  setCftDownloadedOrCreated: (val: boolean) => void;
};

export default function ConfigureAccountDetails({
  closeModal,
  accountName,
  isEdit,
  initUnifiedCftDisabled,
  setCftDownloadedOrCreated,
  setDisabled,
}: ConfigureAccountDetailsProps) {
  const { watch, setError } = useFormContext();
  const {
    state: { steps },
  } = useWizardContext();

  const [customMemberRoleNameEnabled, unifiedCftDisabled] = useWatch({
    name: ["customMemberRoleNameEnabled", "unifiedCftDisabled"],
  });

  const { accountType, partitionType: awsPartition = "" } = steps[0]
    ?.values as AwsGetStartedStepValuesType;

  const accountGroupsMultiSelectProps = {
    accountType,
    selectedCloud: AWS,
  };

  const { groupIds: accountGroupDefaultValue } = steps[1]
    ?.values as AwsConfigureAccountStepValuesType;

  const name = watch("name");
  const { data, refetch, isFetching } = useQuery({
    queryKey: cloudAccountsKeys.accountNameExists({ name }),
    queryFn: accountNameExists,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (isFetching) {
      setDisabled(true);
    } else {
      if ((!isEdit || name !== accountName) && !isEmpty(data) && data.exists) {
        setError("name", {
          message: "Cloud Account Name Already exists",
        });
      } else {
        setDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const { dispatch: selectMemberAccountsDispatch } =
    useAWSSelectMemberAccountsContext();

  const onRoleArnBlur = (event: React.FocusEvent<HTMLInputElement, Element>) =>
    selectMemberAccountsDispatch(setRoleArn(event?.target?.value ?? ""));

  const onAccountIdBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) => selectMemberAccountsDispatch(setAccountId(event?.target?.value ?? ""));

  const onAccountNameBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    refetch();
    selectMemberAccountsDispatch(setAccountName(event?.target?.value ?? ""));
  };

  const onMemberRoleNameBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) =>
    selectMemberAccountsDispatch(setMemberRoleName(event?.target?.value ?? ""));

  const { supportedFeaturesList } = useGetSupportedFeaturesList({
    cloudType: AWS,
    payload: {
      accountType,
      awsPartition,
    },
  });

  const isRemediationSupported = useMemo(
    () => supportedFeaturesList.some((feature) => feature === REMEDIATION),
    [supportedFeaturesList],
  );

  return (
    <section className="space-y-2">
      <Title level={5} size="xxs">
        <FormattedMessage
          defaultMessage="{accountType} Details"
          id="c43ceE"
          description="Section header for account details"
          values={{
            accountType: accountType === ACCOUNT ? "Account" : "Organization",
          }}
        />
      </Title>
      <Subtitle appearance="bold">
        <FormattedMessage
          defaultMessage="Add the necessary details for Prisma Cloud to apply permissions for capabilities and create stack."
          id="nYIg7R"
          description="Section subtitle for account details"
        />
      </Subtitle>
      <div className="space-y-4">
        <div className="flex w-full space-x-4">
          <div className="w-1/2">
            <AccountId isEdit={isEdit} onBlur={onAccountIdBlur} />
          </div>
          <div className="w-1/2">
            <AccountName onBlur={onAccountNameBlur} />
          </div>
        </div>

        {isRemediationSupported && <RemediationCard />}

        <AdvancedConfiguration />

        {unifiedCftDisabled ? (
          <ManagementOrMemberIAMRoleCft
            closeModal={closeModal}
            isEdit={isEdit}
            setCftDownloadedOrCreated={setCftDownloadedOrCreated}
            initUnifiedCftDisabled={initUnifiedCftDisabled}
          />
        ) : (
          <CreateOrDownloadIAMRoleCft
            closeModal={closeModal}
            isEdit={isEdit}
            setCftDownloadedOrCreated={setCftDownloadedOrCreated}
          />
        )}

        {isEdit && <ExternalId />}

        <div className="space-y-4">
          <div className="flex w-full space-x-4">
            <div
              className={`${customMemberRoleNameEnabled ? "w-1/2" : "w-full"}`}
            >
              <RoleArn onBlur={onRoleArnBlur} />
            </div>
            {customMemberRoleNameEnabled && (
              <div className="w-1/2">
                <MemberRoleName onBlur={onMemberRoleNameBlur} />
              </div>
            )}
          </div>
        </div>

        <SelectMemberAccounts closeModal={closeModal} />

        <AccountGroupSelect
          defaultValue={accountGroupDefaultValue}
          enableMultiSelect={accountGroupsMultiSelect({
            ...accountGroupsMultiSelectProps,
          })}
          itemValue="id"
          name="groupIds"
        />
      </div>
    </section>
  );
}
