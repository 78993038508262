import { useQueries } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  getAccountGroupNameListItems,
  listItemKeys,
  type CloudType,
} from "requests";

export type AccountGroupData = {
  id: string;
  name: string;
};

export type AccountGroupItem = {
  key: AccountGroupData["id"];
  children: AccountGroupData["name"];
  value: string | AccountGroupData;
  context: { itemToString: string };
};

export type AccountGroupDefaultValueType =
  | string
  | string[]
  | AccountGroupData
  | AccountGroupData[]
  | null
  | undefined;

type UseAccountGroupItemsReturnValue = {
  items: AccountGroupItem[];
  normalizedDefaultValue?: AccountGroupDefaultValueType;
  isLoading: boolean;
};

export type AccountGroupItemValueType = "id" | "name" | "object";

export type UseAccountGroupItemsProps = {
  itemValue?: AccountGroupItemValueType;
  defaultValue?: AccountGroupDefaultValueType;
  cloudTypes?: CloudType[];
};

export function useAccountGroupItems({
  itemValue = "object",
  defaultValue,
  cloudTypes = [],
}: UseAccountGroupItemsProps): UseAccountGroupItemsReturnValue {
  const queries = (cloudTypes.length ? cloudTypes : [undefined]).map(
    (cloudType) => ({
      queryKey: listItemKeys.accountGroupNames(cloudType),
      queryFn: getAccountGroupNameListItems,
    }),
  );

  const queryResults = useQueries({ queries });

  const isLoading = queryResults.some((q) => q.isLoading);

  const items = useMemo(() => {
    if (isLoading) return [];
    const dedupeSet = new Set();
    return queryResults.reduce<AccountGroupItem[]>(
      (accumulatedItems, { data: accountGroups }) => {
        if (!accountGroups) return accumulatedItems;

        accountGroups?.forEach((accountGroup) => {
          if (dedupeSet.has(accountGroup.id)) return;

          accumulatedItems.push({
            key: accountGroup.id,
            children: accountGroup.name,
            value:
              itemValue === "id"
                ? accountGroup.id
                : itemValue === "name"
                  ? accountGroup.name
                  : { id: accountGroup.id, name: accountGroup.name },
            context: {
              itemToString: `${accountGroup?.id}${accountGroup?.name}`,
            },
          });
          dedupeSet.add(accountGroup.id);
        });
        return accumulatedItems;
      },
      [],
    );
  }, [isLoading, itemValue, queryResults]);

  const normalizedDefaultValueData = useMemo(() => {
    return getNormalizedDefaultValue(itemValue, items, defaultValue);
  }, [defaultValue, items, itemValue]);

  return {
    items,
    isLoading,
    normalizedDefaultValue: defaultValue
      ? normalizedDefaultValueData
      : undefined,
  };
}

export function getNormalizedDefaultValue<
  Item extends { value: unknown; key: string },
>(
  itemValue: "id" | "name" | "object" = "object",
  items: Item[],
  defaultValue?: AccountGroupDefaultValueType,
): AccountGroupDefaultValueType {
  // Filtered values based only on id rather than the complete object
  // If defaultValue is an array of objects,
  //  this value overrides the defaultValue to include on the values that are valid and exist within the items object
  const validDefaultValue = (): AccountGroupDefaultValueType => {
    if (
      !defaultValue ||
      !Array.isArray(defaultValue) ||
      (Array.isArray(defaultValue) && typeof defaultValue[0] === "string") ||
      ["id", "name"].includes(itemValue)
    ) {
      return defaultValue;
    }

    // This statement should ideally be the first condition but we need to make sure that the return is valid for that context
    if (!items.length) return [];

    const validValue = (defaultValue as AccountGroupData[])
      .filter(({ id }) => items.find(({ key }) => key === id))
      .map((d) => {
        const existingItem = items.find(({ key }) => key === d.id)?.value || {};
        if (existingItem && typeof existingItem !== "string") {
          //@ts-expect-error - {...d, ...existingItem} broke unit test, but fix ts error
          d = { ...existingItem };
        }
        return d;
      });
    return validValue;
  };

  const normalizedDefaultValue = (): AccountGroupDefaultValueType => {
    const validDefault = validDefaultValue();

    if (itemValue === "object") {
      if (Array.isArray(validDefault)) {
        if (typeof validDefault[0] === "string") {
          return items
            .map((i) => i.value as AccountGroupData)
            .filter(
              ({ id, name }: AccountGroupData) =>
                (Array.isArray(validDefault) &&
                  (validDefault as string[]).includes(id)) ||
                (Array.isArray(validDefault) &&
                  (validDefault as string[]).includes(name)),
            ) as AccountGroupData[];
        }
        return validDefault as AccountGroupData[];
      }

      if (typeof validDefault === "string") {
        return items
          .map((i) => i.value as AccountGroupData)
          .find(
            ({ id, name }: AccountGroupData) =>
              id === validDefault || name === validDefault,
          );
      }

      return validDefault;
    }

    return validDefault;
  };

  return normalizedDefaultValue();
}
