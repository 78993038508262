import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { type ControlledFilterBarProps, type FilterObject } from "filters";
import { useUCDMode, useUCDModeStore } from "prisma";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { debounce, equals } from "remeda";
import {
  createSavedView,
  deleteSavedView,
  getSavedView,
  getSavedViews,
  reorderSavedViews,
  savedViewKeys,
  updateSavedView,
  type Collections,
  type CreatableSavedView,
  type GetSavedViewsResponse,
  type SavedViewBaseWithSettings,
  type TablePreferencesType,
  type UCDMode,
} from "requests";
import { usePrevious } from "ui";
import { type z } from "zod";
import { isReadOnly } from "./utils";

const MISSING_ID = "404";

export const createSavedViews = <
  SavedView extends SavedViewBaseWithSettings,
  Schema extends z.AnyZodObject,
>({
  collection,
  schema,
}: {
  collection: Collections;
  schema: Schema;
}) => {
  const hooks = {
    collection,
    schema,
    getViewsQueryOptions: () => {
      const persona = useUCDModeStore.getState().mode as UCDMode;

      return {
        queryKey: savedViewKeys.views({
          collection,
          persona,
        }),
        queryFn: getSavedViews,
      };
    },
    getViewQueryOptions: (viewId = MISSING_ID) => {
      return {
        queryKey: savedViewKeys.view({
          id: viewId,
          collection: collection,
        }),
        queryFn: getSavedView(schema),
        enabled: !!viewId && viewId !== MISSING_ID,
        // We don't want settings to reset when changing back and forth in views.
        // If the user is on a curated view the local state may be unsaved.
        staleTime: Infinity,
      };
    },
    useViews: () => {
      const persona = useUCDMode() as UCDMode;

      return useQuery({
        queryKey: savedViewKeys.views({ collection, persona }),
        queryFn: getSavedViews,
        select: (data) => data.views,
        placeholderData: keepPreviousData,
      });
    },
    useOrder: () => {
      const persona = useUCDMode() as UCDMode;

      return useQuery({
        queryKey: savedViewKeys.views({ collection, persona }),
        queryFn: getSavedViews,
        select: (data) => data.order[persona],
      });
    },
    useReorderViews: () => {
      const queryClient = useQueryClient();
      // it's technically possible to be undefined but shouldn't be when it gets here
      // ideally we set a default so it is never undefined
      const persona = useUCDMode() as UCDMode;

      return useMutation({
        mutationFn: ({
          order,
        }: {
          order: Parameters<typeof reorderSavedViews>[0]["order"];
        }) => {
          return reorderSavedViews({
            collection,
            order,
            persona,
          });
        },
        onMutate: async ({ order }) => {
          await queryClient.cancelQueries({
            queryKey: savedViewKeys.views({ collection, persona }),
          });

          const currentCollection = queryClient.getQueryData(
            savedViewKeys.views({ collection, persona }),
          ) as GetSavedViewsResponse;

          queryClient.setQueryData(
            savedViewKeys.views({ collection, persona }),
            () => ({
              ...currentCollection,
              order: {
                ...currentCollection.order,
                [persona]: order,
              },
            }),
          );
        },
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: savedViewKeys.views({ collection, persona }),
          });
        },
      });
    },

    useView: <ViewData = SavedView>(
      id?: string,
      selectFn?: (view: SavedView) => ViewData,
    ) => {
      return useQuery({
        ...hooks.getViewQueryOptions(id),
        select: selectFn,
      });
    },
    useCreateView: () => {
      const persona = useUCDMode() as UCDMode;
      const queryClient = useQueryClient();

      return useMutation({
        mutationFn: async ({
          view,
        }: {
          view: Omit<CreatableSavedView<SavedView>, "visible">;
        }) =>
          await createSavedView<SavedView, Schema>({
            schema,
            collection,
            view: { ...view, visible: { [persona]: true } },
          }),
        onSuccess: async (view) => {
          queryClient.setQueryData(
            savedViewKeys.view({ collection, id: view.id }),
            () => view,
          );
        },
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: savedViewKeys.allViews({ collection }),
          });
        },
      });
    },
    useUpdateViewSettings: (id: string, replace = false) => {
      const queryClient = useQueryClient();

      return useMutation({
        mutationFn: (settings: SavedView["settings"]) => {
          const currentView: SavedView | undefined = queryClient.getQueryData(
            savedViewKeys.view({ id, collection }),
          );

          if (currentView && isReadOnly(currentView)) {
            return new Promise((resolve) => resolve(settings));
          }

          return updateSavedView<SavedView, Schema>({
            schema,
            collection,
            id,
            view: { settings },
          });
        },
        onMutate: async (newSettings) => {
          // Cancel any outgoing refetches
          // (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries({
            queryKey: savedViewKeys.view({ collection, id }),
          });

          const previousView: SavedView | undefined = queryClient.getQueryData(
            savedViewKeys.view({ collection, id }),
          );

          const originalViewQueryKey = savedViewKeys.original({
            collection,
            id,
          });
          const originalView = queryClient.getQueryData(originalViewQueryKey);
          if (!originalView && previousView && isReadOnly(previousView)) {
            queryClient.setQueryData(originalViewQueryKey, previousView);
          }

          queryClient.setQueryData(
            savedViewKeys.view({ collection, id }),
            // @ts-expect-error Typescript doesn't like mixing the full SavedView with the partial settings from the mutate
            (old?: SavedView = {}) => {
              const settings = replace
                ? newSettings
                : { ...(old?.settings ?? {}), ...newSettings };

              return {
                ...old,
                settings: settings,
              };
            },
          );

          return { previousView };
        },
        onError: (_err, _newTodo, context) => {
          context?.previousView &&
            queryClient.setQueryData(
              savedViewKeys.view({ collection, id }),
              context.previousView,
            );
        },
      });
    },
    useShareView: () => {
      const queryClient = useQueryClient();

      return useMutation({
        mutationFn: ({
          id,
          share,
          visibleByDefault,
        }: {
          id: string;
          share: boolean;
          visibleByDefault?: boolean;
        }) => {
          return updateSavedView<SavedView, Schema>({
            collection,
            id,
            view: {
              category: share ? "shared_global" : "custom",
              visibleByDefault,
            },
            schema,
          });
        },
        onSettled: (data) => {
          queryClient.invalidateQueries({
            queryKey: savedViewKeys.allViews({ collection }),
          });
          if (data) {
            queryClient.invalidateQueries({
              queryKey: savedViewKeys.view({ collection, id: data.id }),
            });
          }
        },
      });
    },
    useRenameView: () => {
      const queryClient = useQueryClient();

      return useMutation({
        mutationFn: ({ id, name }: { id: string; name: SavedView["name"] }) => {
          return updateSavedView<SavedView, Schema>({
            collection,
            id,
            view: { name },
            schema,
          });
        },
        onSettled: (data) => {
          queryClient.invalidateQueries({
            queryKey: savedViewKeys.allViews({ collection }),
          });
          if (data) {
            queryClient.invalidateQueries({
              queryKey: savedViewKeys.view({ collection, id: data.id }),
            });
          }
        },
      });
    },
    useUpdateViewVisibility: () => {
      const queryClient = useQueryClient();
      // it's technically possible to be undefined but shouldn't be when it gets here
      // ideally we set a default so it is never undefined
      const persona = useUCDMode() as UCDMode;

      return useMutation({
        mutationFn: ({ id, visible }: { id: string; visible: boolean }) => {
          return updateSavedView<SavedView, Schema>({
            collection,
            id,
            view: { visible: { [persona]: visible } },
            schema,
          });
        },
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: savedViewKeys.views({ collection, persona }),
          });
        },
      });
    },
    useCopyView: () => {
      const intl = useIntl();
      const persona = useUCDMode() as UCDMode;
      const queryClient = useQueryClient();

      return useMutation({
        mutationFn: async (id: string) => {
          const viewToCopy = await queryClient.ensureQueryData({
            queryKey: savedViewKeys.view({
              collection,
              id,
            }),
            queryFn: getSavedView(schema),
          });

          return await createSavedView<SavedView, Schema>({
            schema,
            collection,
            view: {
              name: intl.formatMessage(
                {
                  defaultMessage: "Copy of {name}",
                  id: "ajmFLb",
                  description: "Copied view name",
                },
                { name: viewToCopy.name },
              ),
              visible: { [persona]: true },
              settings: { ...viewToCopy.settings },
            },
          });
        },
        onSuccess: async (view) => {
          queryClient.setQueryData(
            savedViewKeys.view({ collection, id: view.id }),
            () => view,
          );
        },
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: savedViewKeys.allViews({ collection }),
          });
        },
      });
    },
    useDeleteView: () => {
      const queryClient = useQueryClient();

      return useMutation({
        mutationFn: (id: string) => {
          return deleteSavedView({ collection, id });
        },
        onSettled: (_, _2, variables) => {
          queryClient.invalidateQueries({
            queryKey: savedViewKeys.allViews({ collection }),
          });
          queryClient.resetQueries({
            queryKey: savedViewKeys.view({ collection, id: variables }),
          });
        },
      });
    },

    // requirements:
    // 1. If external filters exist on load, use those and update the view
    // 2. Update external filters on change of filters
    // 3. If external filters change(browser navigation), update saved view
    // 4. If the filter state is missing or incorrect, filter bar will update it
    /**
     * Syncs filters in saved views with an external store.
     * Most often used with search params.
     */
    useSyncExternalFilters: ({
      viewId,
      onFilterChange,
      externalFilters,
    }: {
      viewId: string;
      /**
       * Is called when the filters are changed with the new set of filters.
       *
       * Replace is used to signal when the change is considered new vs it should swap out the previous instance.
       * This is intended for navigation and determining when to replace history or create a new entry.
       */
      onFilterChange: (filters: FilterObject, replace: boolean) => void;
      externalFilters?: FilterObject;
    }) => {
      const saveState = hooks.useUpdateViewSettings(viewId);
      const displayedFilters = hooks.useView(
        viewId,
        (view) =>
          (
            view.settings
              ?.filterState as ControlledFilterBarProps["currentState"]
          )?.displayedFilters,
      );
      const filters = hooks.useView(
        viewId,
        (view) =>
          (
            view.settings
              ?.filterState as ControlledFilterBarProps["currentState"]
          )?.filters,
      );
      const shouldInitialSync = useRef(true);
      const previousFilters = usePrevious(filters.data);
      const previousExternalFilters = usePrevious(externalFilters);

      useEffect(() => {
        const isFilterSearchSame = equals(filters.data, externalFilters);

        if (isFilterSearchSame) return;

        const isFiltersChanged = !equals(previousFilters, filters.data);
        const isExternalChanged = !equals(
          previousExternalFilters,
          externalFilters,
        );

        if (!filters.isSuccess) return;

        if (shouldInitialSync.current) {
          shouldInitialSync.current = false;

          if (externalFilters) {
            saveState.mutate({
              filterState: {
                displayedFilters: displayedFilters.data,
                filters: externalFilters,
              },
            });
          } else {
            filters.data && onFilterChange(filters.data, true);
          }
        } else if (isFiltersChanged) {
          filters.data && onFilterChange(filters.data, !externalFilters);
        } else if (!!externalFilters && isExternalChanged) {
          saveState.mutate({
            filterState: {
              displayedFilters: displayedFilters.data,
              filters: externalFilters,
            },
          });
        }
      }, [
        saveState,
        displayedFilters.data,
        filters.isSuccess,
        filters.data,
        externalFilters,
        onFilterChange,
        previousFilters,
        previousExternalFilters,
      ]);
    },
    useSyncTableState: (
      viewId: string,
      tableStateKey: string = "tableState",
    ) => {
      const { mutate } = hooks.useUpdateViewSettings(viewId);
      const queryClient = useQueryClient();
      const currentTableState = hooks.useView(
        viewId,
        (view) => view.settings[tableStateKey] as TablePreferencesType,
      );

      const onStateChange = debounce(
        (state: TablePreferencesType) => {
          const {
            columnOrder,
            hiddenColumns,
            globalFilter,
            groupBy,
            sortBy,
            columnResizing,
          } = state;
          const currentView: SavedView | undefined = queryClient.getQueryData(
            savedViewKeys.view({ id: viewId, collection }),
          );
          const currentState = currentView?.settings?.[tableStateKey];

          const newState = {
            columnOrder,
            // if undefined it won't be sent to the API and then always be dirty
            globalFilter: globalFilter ?? "",
            groupBy,
            hiddenColumns,
            sortBy,
            columnResizing,
          };

          if (equals(currentState, newState)) return;

          mutate({ [tableStateKey]: newState });
        },
        { waitMs: 500 },
      );

      return {
        initialState: currentTableState.data,
        onStateChange: onStateChange.call,
      };
    },
  };

  return hooks;
};

export type CollectionHooks = ReturnType<typeof createSavedViews>;
