// existing versions of inventory apis
// `ValidApiVersions` below expects an ordered array
// Only add new values to end, else update logic below
const validApiVersions = [2, 3, 4];

// checks input parameters (usually LaunchDarkly flag values)
// to return the closest valid version less than or equal to
// the version determined by the given parameters
export function determineApiVersion({
  requestedApiVersion,
  useV3Api,
}: {
  requestedApiVersion?: number;
  useV3Api?: boolean;
}) {
  // logic to determine api version from input parameters
  const translatedApiVersion = requestedApiVersion || (useV3Api ? 3 : 2);

  // find closest valid version that is not greater than the translated version
  const apiVersion = validApiVersions.includes(translatedApiVersion)
    ? translatedApiVersion
    : validApiVersions.reduce(
        (closest, current) =>
          current <= translatedApiVersion && current > closest
            ? current
            : closest,
        validApiVersions[0],
      );

  return apiVersion;
}
