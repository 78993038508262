import { RadioInput, useFormContext } from "form";
import { InfoTooltipIcon } from "icons";
import { useIntl } from "react-intl";
import { Body, DynamicFormattedMessage, Tooltip } from "ui";
import { selectAuthenticationModesMap } from "./messages";

export default function SelectAuthenticationMode() {
  const intl = useIntl();
  const { watch } = useFormContext();
  const authMode = watch("authenticationMode");
  return (
    <section>
      <div className="flex items-center space-x-1 text-xs font-bold leading-5">
        <DynamicFormattedMessage
          messageMap={selectAuthenticationModesMap}
          token="heading"
        />
        <Tooltip
          label={intl.formatMessage(selectAuthenticationModesMap.tooltip)}
        >
          <span>
            <InfoTooltipIcon
              className="ml-1"
              aria-label={intl.formatMessage(
                selectAuthenticationModesMap.tooltip,
              )}
            />
          </span>
        </Tooltip>
      </div>

      <div className="ml-4 mt-2.5 flex space-x-8">
        <label
          key="Application Client Secret"
          className="flex w-max cursor-pointer items-center space-x-2"
        >
          <RadioInput
            name="authenticationMode"
            value="application_client_secret"
            defaultChecked={!authMode}
          />
          <Body size="sm">
            <DynamicFormattedMessage
              messageMap={selectAuthenticationModesMap}
              token="applicationClientSecret"
            />
          </Body>
        </label>
        <label
          key="Workload Identity Federation"
          className="flex w-max cursor-pointer items-center space-x-2"
        >
          <RadioInput name="authenticationMode" value="external_account" />
          <Body size="sm">
            <DynamicFormattedMessage
              messageMap={selectAuthenticationModesMap}
              token="workloadIdentityFederation"
            />
          </Body>
        </label>
      </div>
    </section>
  );
}
