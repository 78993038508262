import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { isEmpty } from "remeda";

import { LoadingIcon, UsersIcon } from "icons";

import { grayScale } from "colors";
import { useWizardContext } from "../../../../../../components/Wizard";
import {
  ALL,
  AZURE,
  AZURE_HIERARCHY_SELECTION,
  INCLUDE,
  TENANT,
} from "../../../../../constants";
import { useIsRenderedFirstTime, useIsUnMounted } from "../../../../../hooks";
import { getOUPreviouslySelectedNodesErrorMessage } from "../../../../../utils";
import {
  Accordion,
  AccordionToggle,
} from "../../../components/CustomAccordion";
import { getCredentialsErrorMessage } from "../../../components/SelectMemberAccountCredentialError";
import { useAzureSelectMonitoredProjectContext } from "../../context/AzureSelectMonitoredProjectContext";
import { setAzureProjectSelectMode } from "../../context/AzureSelectMonitoredProjectContext/state/actions";
import {
  getApplicationId,
  getApplicationKey,
  getAzureFoldersCredentialError,
  getAzurePreviouslySelectedNodesNotFound,
  getAzureProjectSelectMode,
  getAzureSelectProjectsIsLoading,
  getHierarchySelection,
  getServicePrincipalId,
  getTenantId,
} from "../../context/AzureSelectMonitoredProjectContext/state/getters";
import AzureChooseMonitoredProjects from "./AzureChooseMonitoredProjects";
import { messageMap } from "./messages";

type SelectMonitoredProjectsProps = {
  closeModal: () => void;
};

export default function SelectMonitoredProjects(
  props: SelectMonitoredProjectsProps,
) {
  const {
    state: { steps },
  } = useWizardContext();

  const { accountType } = steps[0]?.values ?? {};

  if (accountType !== TENANT) {
    return null;
  }
  return <TreeHierarchySelection {...props} />;
}

function TreeHierarchySelection({ closeModal }: SelectMonitoredProjectsProps) {
  const intl = useIntl();
  const { getValues } = useFormContext();
  const isUnMounted = useIsUnMounted();

  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedFirstTime = useIsRenderedFirstTime();

  const { state, dispatch } = useAzureSelectMonitoredProjectContext();

  const {
    valuesUpdate,
    state: { activeIndex },
  } = useWizardContext();
  const { watch } = useFormContext();

  const clientId = getApplicationId(state);
  const tenantId = getTenantId(state);
  const applicationKey = getApplicationKey(state);
  const servicePrincipalId = getServicePrincipalId(state);

  const mode = getAzureProjectSelectMode(state);
  const isLoading = getAzureSelectProjectsIsLoading(state) && mode === ALL;
  const apiFolderCredentialError = getAzureFoldersCredentialError(state);
  const previouslySelectedNodesNotFound =
    getAzurePreviouslySelectedNodesNotFound(state);

  const isWIF = watch("authenticationMode") === "external_account";

  const disabled =
    isEmpty(clientId) ||
    isEmpty(tenantId) ||
    (!isWIF && isEmpty(applicationKey)) ||
    isEmpty(servicePrincipalId) ||
    !isEmpty(apiFolderCredentialError);

  const [isDisabled, setDisabled] = useState(() => mode !== ALL);

  const toggle = () => {
    setAzureProjectSelectMode(isDisabled ? ALL : INCLUDE, dispatch);
  };

  // Open/close accordion on mode change
  useEffect(() => {
    if (!isRenderedFirstTime) {
      setDisabled(mode !== ALL);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // Change mode to all if toggle state is disabled
  useEffect(() => {
    if (disabled) {
      setAzureProjectSelectMode(ALL, dispatch);
    }
  }, [disabled, dispatch]);

  // Get credentials and prevSelectedNode Error Messages
  const selectProjectErrors = useMemo(() => {
    const credErr = getCredentialsErrorMessage({
      errorMessage: apiFolderCredentialError,
      type: AZURE,
      intl,
    });

    const prevNodeErr = getOUPreviouslySelectedNodesErrorMessage({
      data: previouslySelectedNodesNotFound,
      intl,
    });

    return [...credErr, ...prevNodeErr].filter((v) => !isEmpty(v));
  }, [apiFolderCredentialError, intl, previouslySelectedNodesNotFound]);

  // Update step context and save treenodes selection on unmount of component
  const updateStepValues = useCallback(() => {
    if (isUnMounted.current) {
      const values = getValues();
      const hierarchySelectionSelectedNodes = getHierarchySelection(state);

      valuesUpdate({
        index: activeIndex,
        values: {
          ...values,
          [AZURE_HIERARCHY_SELECTION]: hierarchySelectionSelectedNodes,
        },
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    return () => updateStepValues();
  }, [updateStepValues]);

  const isCardOpen = isDisabled;
  const isToggleChecked = !isDisabled;

  return (
    <Accordion
      description={intl.formatMessage(messageMap.description)}
      disabled={disabled}
      errors={selectProjectErrors}
      isOpen={isCardOpen}
      icon={<UsersIcon size="lg" color={grayScale[600]} />}
      menuToggle={
        isLoading ? (
          <LoadingIcon size="md" />
        ) : (
          <AccordionToggle
            checked={isToggleChecked}
            disabled={disabled}
            onClick={toggle}
          />
        )
      }
      title={intl.formatMessage(messageMap.title)}
    >
      <AzureChooseMonitoredProjects closeModal={closeModal} />
    </Accordion>
  );
}
