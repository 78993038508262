import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormLayout } from "form";
import { useCallback, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  cloudAccountsKeys,
  createDataSecurityConfig,
  getAwsCloudAccountStatus,
  getDlpStatus,
  saveAwsCloudAccount,
  tenantKeys,
  updateAwsCloudAccount,
  updateDataSecurityConfig,
  type AwsRequestPayloadType,
  type AwsStatusResponseType,
  type SecurityCapabilitiesFeatures,
  type SecurityCapabilitiesType,
} from "requests";
import { useToastActions } from "stores";
import { useModal } from "ui";
import {
  PrevButton,
  WizardForm,
  useWizardContext,
} from "../../../../../components/Wizard";
import {
  ACCOUNT,
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  AWS,
  CONFIGURE_ACCOUNT,
  CONFIGURE_DATA_SECURITY,
  DATA_SECURITY,
  DEFINE_FW,
  DISABLED,
  ENABLED,
  GET_STARTED,
  ORGANIZATION,
  REMEDIATION,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
} from "../../../../constants";
import {
  useGetAwsCloudAccountsCas,
  useGetSupportedFeaturesList,
  useIdentitySubscription,
  useIsRenderedFirstTime,
} from "../../../../hooks";
import {
  type AwsConfigureAccountStepValuesType,
  type AwsGetStartedStepValuesType,
} from "../../../../types";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useCloudAccountPendoGuide } from "../../../../../../../utils/useCloudAccountPendoGuide";
import { parseI18nKeyFromResponse } from "../../../../utils";
import CloudSelection from "../../../CloudSelection";
import { ProductDocumentationLink } from "../../components";
import SaveAndCloseButton from "../../components/SaveAndCloseButton";
import SaveAndOnboardButton from "../../components/SaveAndOnboardButton";
import {
  getAccountIdFromRoleArn,
  getAuthenticationData,
  getSecurityCapabilities,
  getWizardStepIndex,
  handleSuccess,
} from "../../utils";
import AccountDetailsStatus from "./AccountDetailsStatus";
import SecurityCapabilitiesPermissions from "./SecurityCapabilitiesPermissions";

type ReviewStatusProps = {
  context: {
    closeModal: () => void;
    enabled: boolean;
    isEdit: boolean;
  };
};

export default function ReviewStatus({
  context: { closeModal, enabled, isEdit },
}: ReviewStatusProps) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { toast } = useToastActions();
  const { agentlessApiDiscovery, cloudScanMode } = useFlags();
  const { showGuide } = useCloudAccountPendoGuide();
  const {
    state: { steps },
  } = useWizardContext();

  const { openModal } = useModal(CloudSelection);
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedForFirstTime = useIsRenderedFirstTime();
  const getStepIndex = getWizardStepIndex(steps);
  const getStartedStepIndex = getStepIndex(GET_STARTED);
  const configureAccountIndex = getStepIndex(CONFIGURE_ACCOUNT);
  const defineFwStepIndex = getStepIndex(DEFINE_FW);
  const configureDataSecurityStepIndex = getStepIndex(CONFIGURE_DATA_SECURITY);

  const {
    accountType,
    partitionType: awsPartition,
    securityCapabilities,
    orgSecurityCapabilities,
    mode = "",
  } = (steps[getStartedStepIndex]?.values ?? {}) as AwsGetStartedStepValuesType;

  const {
    name,
    roleArn,
    groupIds,
    remediation,
    hierarchySelection,
    externalId,
  } = (steps[configureAccountIndex]?.values ??
    {}) as AwsConfigureAccountStepValuesType;

  const accountId = getAccountIdFromRoleArn(roleArn) as string;

  const { supportedFeaturesList, isLoading: isFeaturesLoading } =
    useGetSupportedFeaturesList({
      cloudType: AWS,
      payload: {
        accountType,
        awsPartition,
      },
    });

  const { data: awsData, isLoading: isAwsDataLoading } =
    useGetAwsCloudAccountsCas({
      accountId,
      options: { enabled: isEdit },
    });

  const { isSubscribed, isLoading: indentitySecurityLoading } =
    useIdentitySubscription();

  const { storageUUID = "" } = awsData || {};

  const isAccountType = accountType === ACCOUNT;
  const isOrg = accountType === ORGANIZATION;
  const isGovcloudSelected = awsPartition.includes("gov");

  const features = [
    {
      name: AGENTLESS_SCAN,
      state: securityCapabilities[AGENTLESS_SCAN] ? ENABLED : DISABLED,
      ...(isOrg && {
        defaultMemberState:
          securityCapabilities[AGENTLESS_SCAN] &&
          orgSecurityCapabilities[AGENTLESS_DEFAULT_MEMBER_STATE]
            ? ENABLED
            : DISABLED,
      }),
      ...(cloudScanMode &&
        !isGovcloudSelected &&
        securityCapabilities[AGENTLESS_SCAN] && {
          mode,
        }),
    },
    {
      name: AUTO_PROTECT,
      state: securityCapabilities[AUTO_PROTECT] ? ENABLED : DISABLED,
    },
    {
      name: REMEDIATION,
      state: remediation ? ENABLED : DISABLED,
    },
    {
      name: DATA_SECURITY,
      state: securityCapabilities[DATA_SECURITY] ? ENABLED : DISABLED,
    },
    {
      name: SERVERLESS_SCAN,
      state: securityCapabilities[SERVERLESS_SCAN] ? ENABLED : DISABLED,
      ...(isOrg && {
        defaultMemberState:
          securityCapabilities[SERVERLESS_SCAN] &&
          orgSecurityCapabilities[SERVERLESS_DEFAULT_MEMBER_STATE]
            ? ENABLED
            : DISABLED,
      }),
    },
    agentlessApiDiscovery && {
      name: AGENTLESS_API_DISCOVERY,
      state: securityCapabilities[AGENTLESS_API_DISCOVERY] ? ENABLED : DISABLED,
      ...(isOrg && {
        defaultMemberState:
          securityCapabilities[AGENTLESS_API_DISCOVERY] &&
          orgSecurityCapabilities[AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE]
            ? ENABLED
            : DISABLED,
      }),
    },
  ]
    .filter(Boolean)
    .filter(({ name }) =>
      supportedFeaturesList.includes(name as SecurityCapabilitiesType),
    ) as SecurityCapabilitiesFeatures[];

  const {
    customMemberRoleNameEnabled,
    memberRoleName,
    skipOverrideMemberRoleName,
    unifiedCftDisabled,
    useTenantExternalId,
  } = (steps[configureAccountIndex]?.values ??
    {}) as AwsConfigureAccountStepValuesType;

  let requestData: AwsRequestPayloadType = useMemo(() => {
    return {
      accountId,
      accountType,
      enabled,
      features,
      ...(isAccountType
        ? { groupIds }
        : { defaultAccountGroupId: groupIds as unknown as string }),
      ...(!isAccountType && { hierarchySelection }),
      name,
      roleArn,
      ...(!isAccountType && {
        customMemberRoleNameEnabled,
        ...(customMemberRoleNameEnabled && { memberRoleName }),
        skipOverrideMemberRoleName,
        unifiedCftDisabled,
      }),
      useTenantExternalId,
    };
  }, [
    accountId,
    accountType,
    customMemberRoleNameEnabled,
    enabled,
    features,
    groupIds,
    hierarchySelection,
    isAccountType,
    memberRoleName,
    name,
    roleArn,
    skipOverrideMemberRoleName,
    unifiedCftDisabled,
    useTenantExternalId,
  ]);

  if (securityCapabilities[DATA_SECURITY]) {
    let cloudTrailTopic = steps[defineFwStepIndex]?.values
      ?.cloudTrailTopic as string;
    // in New flow, SNS Topic may be missing prefix and region and account ID
    /* c8 ignore next */
    if (cloudTrailTopic) {
      const prefix = "arn:aws:sns";
      const isMissingPrefix =
        cloudTrailTopic.substring(0, prefix.length) !== prefix;

      // add prefix and region and account ID if missing
      if (isMissingPrefix) {
        const defaultRegion = "us-east-1";

        cloudTrailTopic = `${prefix}:${defaultRegion}:${accountId}:${cloudTrailTopic}`;
      } else {
        cloudTrailTopic;
      }
    }
    const scanOption =
      steps[configureDataSecurityStepIndex]?.values?.scanOption;
    const cloudTrailStorageUUID = steps[defineFwStepIndex]?.values
      ?.storageUUID as string;
    const buckets =
      scanOption === "Custom"
        ? {
            backward: [""],
            forward: [""],
          }
        : {
            backward: ["All"],
            forward: ["All"],
          };

    requestData = {
      ...requestData,
      storageScanConfig: {
        buckets,
        scanOption: scanOption as string,
        snsTopicArn: cloudTrailTopic,
      },
      storageUUID:
        isEdit && cloudTrailStorageUUID === undefined
          ? storageUUID
          : cloudTrailStorageUUID,
    };
  }

  const {
    data: accountStatus = [] as AwsStatusResponseType,
    isFetching: isCloudStatusLoading,
    isError,
    error,
  } = useQuery({
    queryKey: cloudAccountsKeys.awsCloudAccountStatus({ payload: requestData }),
    queryFn: getAwsCloudAccountStatus,
    enabled: !isRenderedForFirstTime && !isFeaturesLoading,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (!isError) return;

    toast(parseI18nKeyFromResponse(intl, error), {
      appearance: "error",
    });
    closeModal();
  }, [isError, error, intl, toast, closeModal]);

  const { mutateAsync: createConfig, isPending: isAccountCreating } =
    useMutation({
      mutationFn: () => saveAwsCloudAccount({ payload: requestData }),
    });

  const { mutateAsync: updateConfig, isPending: isAccountUpdating } =
    useMutation({
      mutationFn: () =>
        updateAwsCloudAccount({ accountId, payload: requestData }),
    });

  const {
    data: provisionData,
    isLoading: dlpApiLoading,
    isFetching,
  } = useQuery({
    queryKey: tenantKeys.dlpStatus(),
    queryFn: getDlpStatus,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: isAccountType && securityCapabilities[DATA_SECURITY],
  });

  const { mutateAsync: createNewConfig } = useMutation({
    mutationFn: async () => {
      /* c8 ignore next */
      try {
        createDataSecurityConfig({
          payload: {
            storageUUID: requestData?.storageUUID as string,
            accountId,
            cloudType: "aws",
            dssTenantId: provisionData?.dlpTenantId || "",
            accountType: "regular",
            storageScanStatus: "enabled",
            scanOption:
              steps[configureDataSecurityStepIndex]?.values?.scanOption ===
              "Full"
                ? "full"
                : "custom",
            cloudConfig: {
              externalId,
              roleArn,
            },
          },
        });
      } catch {
        null;
      }
    },
  });

  const { mutateAsync: updateNewConfig } = useMutation({
    mutationFn: async () => {
      /* c8 ignore next */
      try {
        updateDataSecurityConfig({
          accountId,
          payload: {
            cloudType: "aws",
            accountType: "regular",
            dssTenantId: provisionData?.dlpTenantId || "",
            storageScanStatus: "enabled",
            scanOption:
              steps[configureDataSecurityStepIndex]?.values?.scanOption ===
              "Full"
                ? "full"
                : "custom",
            cloudConfig: {
              externalId,
              roleArn,
            },
          },
        });
      } catch {
        null;
      }
    },
  });

  const onSubmit = useCallback(
    async ({ openNewOnboarding = false }) => {
      try {
        if (isEdit) {
          await updateConfig();
        } else {
          await createConfig();
        }
        /* c8 ignore next */
        if (isAccountType && securityCapabilities[DATA_SECURITY]) {
          if (isEdit) {
            await updateNewConfig();
          } else {
            await createNewConfig();
          }
        }
        /* c8 ignore next */
        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.cloudAccountsTable({
            excludeAccountGroupDetails: "true",
          }),
        });

        if (isEdit) {
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.cloudAccountsCas({
              accountId,
              cloudType: AWS,
              includeGroupInfo: false,
            }),
          });
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.awsCloudAccountsCas({ accountId }),
          });
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.permissionsStatus({ accountId }),
          });
          if (isAccountType) {
            queryClient.invalidateQueries({
              queryKey: cloudAccountsKeys.awsAccountDataSecurity({ accountId }),
            });
          }
        }
        closeModal();
        openNewOnboarding && openModal();
        if (!isEdit) showGuide();
        toast(handleSuccess(isEdit, intl), { appearance: "success" });
      } catch (error: unknown) {
        toast(parseI18nKeyFromResponse(intl, error), {
          appearance: "error",
        });
      }
    },
    [
      isEdit,
      isAccountType,
      securityCapabilities,
      queryClient,
      accountId,
      closeModal,
      openModal,
      showGuide,
      toast,
      updateConfig,
      createConfig,
      updateNewConfig,
      createNewConfig,
      intl,
    ],
  );

  const accountDetailsData = {
    accountGroups: Array.isArray(groupIds) ? groupIds : [groupIds],
    accountName: name,
    accountType,
    accountId,
    roleArn,
    securityCapabilities: getSecurityCapabilities(
      securityCapabilities,
      supportedFeaturesList,
      isSubscribed,
    ).filter((feature) =>
      agentlessApiDiscovery ? feature : feature !== AGENTLESS_API_DISCOVERY,
    ),
    authentication: getAuthenticationData(accountStatus),
    deploymentType: awsPartition,
  };

  const isLoading =
    isCloudStatusLoading ||
    isFeaturesLoading ||
    isAwsDataLoading ||
    (dlpApiLoading && isFetching) ||
    indentitySecurityLoading;

  const isSubmitting = isAccountCreating || isAccountUpdating;
  const disableBtn = isLoading || isSubmitting;

  return (
    <WizardForm
      actions={
        <>
          <PrevButton data-selector="prev-button" disabled={disableBtn} />
          {!isEdit && (
            <SaveAndOnboardButton disabled={disableBtn} onSubmit={onSubmit} />
          )}
          <SaveAndCloseButton disabled={disableBtn} onSubmit={onSubmit} />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <section className="space-y-4">
        <span className="text-xs">
          <FormattedMessage
            defaultMessage="Review account details, security capabilities and permissions."
            id="ocHZwf"
            description="description for review status title"
          />
        </span>
        <FormLayout>
          <AccountDetailsStatus {...accountDetailsData} isLoading={isLoading} />
          <SecurityCapabilitiesPermissions
            isLoading={isLoading}
            accountStatus={accountStatus}
            features={features}
            isSubscribed={isSubscribed}
            isOrg={isOrg}
          />
        </FormLayout>
      </section>
    </WizardForm>
  );
}
