import { defineMessages } from "react-intl";

export const legendMessageMap = defineMessages({
  managedAsset: {
    defaultMessage: "Managed Assets",
    id: "1yYAdZ",
    description: "Internet Exposed Unmanaged Assets Legend label",
  },

  remediatedAsset: {
    defaultMessage: "Remediated Findings",
    id: "HM01a8",
    description: "Internet Exposed Unmanaged Assets Legend label",
  },
  unManagedAsset: {
    defaultMessage: "Unmanaged Exposed Assets",
    id: "J5qWk4",
    description: "Internet Exposed Unmanaged Assets Legend label",
  },
});
