import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AzureRepoIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Azure Repo",
    id: "iCRvZD",

    description: "provider icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <title>{alt}</title>
      <path
        className="fill-[#C43601] dark:fill-[#D0D0D0]"
        d="M16.6973 3H7.30114C6.93872 3 6.64746 3.29151 6.64746 3.65392V5.79458H17.3512V3.65368C17.351 3.29126 17.0597 3 16.6973 3Z"
      />
      <path
        className="fill-[#CA3700] dark:fill-[#D3D3D3]"
        d="M17.8039 4.30835H6.19616C5.67302 4.30835 5.25098 4.73064 5.25098 5.25353V5.99702H18.7493V5.25353C18.7491 4.73039 18.327 4.30835 17.8039 4.30835Z"
      />
      <path
        className="fill-[#DD5323] dark:fill-[#FAFAFA]"
        d="M19.1637 5.64893H4.8363C4.31316 5.64893 3.89111 6.07122 3.89111 6.59411V20.0512C3.89111 20.5744 4.31708 21.0001 4.83998 21.0001H19.1601C19.6832 21.0001 20.1089 20.5741 20.1089 20.0512V6.59436C20.1092 6.07122 19.6869 5.64893 19.1637 5.64893Z"
      />
      <path
        className="fill-white dark:fill-[#D0D0D0]"
        d="M8.67678 7.0354C7.68302 7.0354 6.87598 7.84244 6.87598 8.83621C6.87598 9.82998 7.68302 10.637 8.67678 10.637C9.67055 10.637 10.4776 9.82998 10.4776 8.83621C10.4776 7.84219 9.67055 7.0354 8.67678 7.0354ZM8.67678 10.0017C8.03047 10.0017 7.50733 9.4786 7.50733 8.83228C7.50733 8.18596 8.03047 7.66307 8.67678 7.66307C9.3231 7.66307 9.84624 8.18621 9.84624 8.83228C9.84624 9.4786 9.3231 10.0017 8.67678 10.0017Z"
      />
      <path
        className="fill-white dark:fill-[#D0D0D0]"
        d="M9.3529 10.4414H8.01904V14.0916H9.3529V10.4414Z"
      />
      <path
        className="fill-white dark:fill-[#D0D0D0]"
        d="M17.2291 8.86087C17.2291 7.8671 16.422 7.06006 15.4283 7.06006C14.4345 7.06006 13.6274 7.8671 13.6274 8.86087C13.6274 9.62668 14.1057 10.2806 14.7819 10.5419V11.7786L8.01989 14.0128H8.00861V16.3028C7.34732 16.5681 6.88037 17.2181 6.88037 17.9729C6.88037 18.9666 7.68741 19.7737 8.68118 19.7737C9.67495 19.7737 10.482 18.9666 10.482 17.9729C10.482 17.2257 10.0261 16.5831 9.37976 16.3102V14.8905L16.1045 12.6676L16.0895 12.6414H16.1045V10.5267C16.7619 10.262 17.2291 9.61564 17.2291 8.86087ZM9.84646 17.9768C9.84646 18.6231 9.32333 19.1463 8.67701 19.1463C8.03069 19.1463 7.50755 18.6231 7.50755 17.9768C7.50755 17.3305 8.03069 16.8073 8.67701 16.8073C9.32357 16.8076 9.84646 17.3305 9.84646 17.9768ZM15.4283 10.0266C14.7819 10.0266 14.2588 9.5035 14.2588 8.85719C14.2588 8.21087 14.7819 7.68797 15.4283 7.68797C16.0746 7.68797 16.5977 8.21111 16.5977 8.85719C16.5975 9.5035 16.0706 10.0266 15.4283 10.0266Z"
      />
    </SvgIcon>
  );
};
