import {
  cloneElement,
  useCallback,
  useMemo,
  type FC,
  type KeyboardEventHandler,
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
  type RefObject,
} from "react";
import { FocusOn } from "react-focus-on";
import { v4 as uuidV4 } from "uuid";

import { classNames, noop } from "utils";
import { useFloating, type Placement } from "../../utils";
import {
  useOptionallyControlledState,
  type SetControlledState,
} from "../../utils/useOptionallyControlledState";
import { Card, type CardProps } from "../Card";
import { Portal } from "../Portal";
import { Tooltip, type TooltipProps } from "../Tooltip";

type Shard = RefObject<HTMLElement> | HTMLElement;

export interface PopoverProps {
  /**
   * A custom element to trigger the Popper, usually a button element.
   */
  button: ReactElement;
  /**
   * Tooltip text to show on button hover
   */
  buttonTooltip?: string;
  buttonTooltipProps?: Omit<TooltipProps, "children" | "label">;
  /**
   * The content of the popper
   */
  children: ReactNode;
  defaultIsOpen?: boolean;
  defaultPlacement?: Placement;
  isOpen?: boolean;
  /**
   * A label for the Trigger Button.
   *
   * This is required for accessibility. Use `showLabel` to restrict the label to screen readers.
   */
  label: string;
  onIsOpenChange?: SetControlledState<boolean>;
  /**
   * Used for onClickOutside & onEscapeKey when the component is controlled
   */
  onClosePopover?(): void;
  /**
   * Makes the label invisible and removes it from the layout, but keeps it in the DOM for screen readers.
   */
  showLabel?: boolean;
  /**
   * If you are using an element in the popover that renders to a different DOM tree, pass
   * a ref to the shards to ensure the focus lock works correctly with the detached element.
   */
  shards?: Shard[];
  cardProps?: Omit<CardProps, "children">;
  zIndex?: string;
  /**
   * If you don't need not set focus to child at start, use props autoFocus with value false
   */
  autoFocus?: boolean;
  /**
   * - When `noIsolation` is `true` (default), user could interact with the elements outside of the popover.
   * Eg. directly click a button outside of the popover.
   * - When `noIsolation` is `false`, the first click outside of the popover will only close the popover,
   * and a second click is needed to actually click the button.
   * This is useful to ensure that `onClickOutside` works, since some elements don't
   * propagate the click event, eg, a graph using d3-zoom.
   */
  noIsolation?: boolean;
}

/**
 * Renders its children in a focused mode without a backdrop.
 *
 * When the Popover is open, all other actions on the page are enabled but focus is trapped within the Popover.
 *
 * Set the initial focus within in the Popover with one of two attributes:
 *
 *   - `data-autofocus` - the specific element will get focus on mount
 *   - `data-autofocus-inside` - the first interactive child will get focus on mount
 *
 * The escape key, and clicking outside the dialog will close the dialog.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Popover, CardBody, CardHeader, CardFooter } from "ui";
 * ```
 */
export const Popover: FC<PopoverProps> = ({
  button,
  buttonTooltip,
  buttonTooltipProps,
  defaultPlacement = "bottom-end",
  children,
  defaultIsOpen = false,
  isOpen: userIsOpen,
  label,
  onIsOpenChange = noop,
  onClosePopover = noop,
  showLabel = true,
  shards,
  cardProps,
  zIndex = "z-20",
  autoFocus = true,
  noIsolation = true,
}: PopoverProps) => {
  const { setReference, setFloating, floatingStyles } = useFloating({
    placement: defaultPlacement,
    strategy: "fixed",
  });

  const [isOpen, setIsOpen] = useOptionallyControlledState<boolean>({
    currentValue: userIsOpen,
    defaultValue: defaultIsOpen,
    onChange: onIsOpenChange,
  });
  const labelId = useMemo((): string => `popover-label-${uuidV4()}`, []);
  const buttonId = useMemo((): string => `popover-button-${uuidV4()}`, []);

  const togglePopover = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const closePopover = useCallback(() => {
    setIsOpen(false);
    onClosePopover();
  }, [onClosePopover, setIsOpen]);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLButtonElement>>(
    (e) => {
      switch (e.key) {
        case " ":
        case "Enter":
          setIsOpen(true);
          e.preventDefault();
      }
    },
    [setIsOpen],
  );

  const buttonElement = cloneElement(button, {
    id: buttonId,
    "aria-haspopup": "dialog",
    "aria-expanded": isOpen,
    "aria-labelledby": `${labelId} ${buttonId}`,
    isOpen,
    onKeyDown: handleKeyDown,
    onMouseDown: togglePopover,
    ref: setReference,
  });

  return (
    <>
      <span
        className={classNames("flex", showLabel ? "space-x-2" : "relative")}
      >
        <label
          id={labelId}
          className={classNames(
            "text-sm leading-loose",
            !showLabel && "sr-only",
          )}
        >
          {label}
        </label>
        {buttonTooltip ? (
          <Tooltip label={buttonTooltip} {...buttonTooltipProps}>
            <div>{buttonElement}</div>
          </Tooltip>
        ) : (
          <>{buttonElement}</>
        )}
      </span>
      <Portal>
        <div ref={setFloating} className={zIndex} style={floatingStyles}>
          {isOpen && (
            <FocusOn
              autoFocus={autoFocus}
              enabled={isOpen}
              noIsolation={noIsolation}
              onClickOutside={closePopover}
              onEscapeKey={closePopover}
              scrollLock={false}
              shards={shards}
            >
              <Card role="dialog" shadow {...cardProps}>
                {children}
              </Card>
            </FocusOn>
          )}
        </div>
      </Portal>
    </>
  );
};
