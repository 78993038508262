import { accountGroupNameKeys } from "../accountGroupName";
import { alertRuleConfigureNotificationKeys } from "../alertRule";
import {
  cloudAccountIdKeys,
  type CloudAccountIdKeysProps,
} from "../cloudAccountId";
import {
  cloudAccountNameKeys,
  type CloudAccountNameKeysProps,
} from "../cloudAccountName";
import { cloudRegionKeys } from "../cloudRegion";
import { cloudTypeKeys, type CloudType } from "../cloudType";
import { complianceStandardsKeys } from "../compliance";
import {
  notificationTemplateKeys,
  type NotificationTemplateType,
} from "../notificationTemplate";
import { permissionGroupsKeys } from "../permissionGroups";
import { resourceListKeys } from "../resourceList";
import { userNameKeys } from "../userNames";
import { suggestionKeys } from "./listItemsFromSuggestEndpoints";

export const listItemKeys = {
  ...suggestionKeys,
  accountGroupNames: (cloudType?: CloudType) =>
    accountGroupNameKeys.accountGroupNames({ cloudType }),
  cloudTypes: cloudTypeKeys.cloudTypes({ onlyAccessible: "false" }),
  cloudTypesWithOther: cloudTypeKeys.cloudTypes({
    onlyAccessible: "false",
    includeOthers: "true",
  }),
  cloudAccountNames: (
    props?: Pick<CloudAccountNameKeysProps, "accountGroupIds">,
  ) => {
    const { accountGroupIds } = props ?? {};

    return cloudAccountNameKeys.cloudAccountNames({
      onlyActive: "true",
      ...(accountGroupIds && { accountGroupIds }),
    });
  },
  cloudAccountNamesWithOther: (
    props?: Pick<
      CloudAccountNameKeysProps,
      "accountGroupIds" | "includeNonOnboardedAccounts"
    >,
  ) => {
    const { accountGroupIds, includeNonOnboardedAccounts } = props ?? {};

    return cloudAccountNameKeys.cloudAccountNames({
      onlyActive: "true",
      includeOthers: "true",
      ...(accountGroupIds && { accountGroupIds }),
      ...(includeNonOnboardedAccounts && { includeNonOnboardedAccounts }),
    });
  },
  cloudAccountIds: ({
    includeOther,
  }: {
    includeOther: CloudAccountIdKeysProps["includeOthers"];
  }) => {
    return cloudAccountIdKeys.cloudAccountIds({
      onlyActive: true,
      includeOthers: includeOther,
    });
  },
  cloudRegions: cloudRegionKeys.cloudRegions(),
  cloudRegionsWithOther: cloudRegionKeys.cloudRegions({
    includeOthers: "true",
  }),
  complianceStandards: complianceStandardsKeys.standards,
  userNames: userNameKeys.userNames(),
  userNamesSSO: userNameKeys.userNames({
    fetchSakUser: "false",
  }),
  computeAccessGroupResourceList: () =>
    resourceListKeys.resourceListByType({ type: "COMPUTE_ACCESS_GROUP" }),
  tagResourceList: () => resourceListKeys.resourceListByType({ type: "TAG" }),
  // Alert Rules Notifications
  amazonSQSIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.amazonSQS({ tenantId }),
  amazonS3Integrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.amazonS3({ tenantId }),
  awsSDLIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.awsSDL({ tenantId }),
  awsSecurityHubIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.awsSecurityHub({ tenantId }),
  azureServiceBusQueueIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.azureBusQueue({ tenantId }),
  cortexXSOARIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.cortexXSOAR({ tenantId }),
  googleCSCCIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.googleCSCC({ tenantId }),
  microsoftTeamsIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.microsoftTeams({ tenantId }),
  pagerDutyIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.pagerDuty({ tenantId }),
  slackIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.slack({ tenantId }),
  snowflakeIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.snowflake({ tenantId }),
  splunkIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.splunk({ tenantId }),
  webhookIntegrations: (tenantId: string) =>
    alertRuleConfigureNotificationKeys.webhook({ tenantId }),
  template: (
    prismaId: string,
    params?: {
      type: NotificationTemplateType;
    },
  ) => notificationTemplateKeys.notificationTemplates(prismaId, params),
  permissionGroups: () =>
    permissionGroupsKeys.permissionGroups({
      includeFeatures: "true",
    }),
} as const;
