import { Fragment } from "react";
import {
  TableProvider,
  type Context,
  type Data,
  type TableProviderProps,
} from "table";
import { noop } from "utils";
import { type CollectionHooks } from "../../query";

type SavedViewsTableProviderProps<
  D extends Data,
  C extends Context = Context,
> = {
  hooks: CollectionHooks;
  viewId: string;
  /**
   * The name of the key the filterstate will be stored in under the saved view settings.
   */
  tableStateKey?: string;
  disableTableStateSync?: boolean;
} & TableProviderProps<D, C>;

export function SavedViewsTableProvider<
  D extends Data,
  C extends Context = Context,
>({
  hooks,
  viewId,
  tableStateKey,
  disableTableStateSync = false,
  ...tableProps
}: SavedViewsTableProviderProps<D, C>) {
  const tableState = hooks.useSyncTableState(viewId, tableStateKey);

  return (
    <Fragment key={viewId}>
      <TableProvider
        {...tableProps}
        {...tableState}
        onStateChange={disableTableStateSync ? noop : tableState.onStateChange}
        // @ts-expect-error the columnResizing.columnWidth from tableState does not align
        initialState={{
          ...(tableProps.initialState ?? {}),
          ...tableState.initialState,
        }}
      />
    </Fragment>
  );
}
