import { defineMessages } from "react-intl";

export const selectAuthenticationModesMap = defineMessages({
  heading: {
    defaultMessage: "Authentication Mode",
    id: "SqYiip",
    description: "Header for Authentication Mode",
  },
  tooltip: {
    defaultMessage:
      "Determines how Prisma Cloud authenticates itself with AZURE.",
    id: "SezY4W",
    description: "Tooltip text for the label select auth type",
  },
  applicationClientSecret: {
    defaultMessage: "Application Client Secret",
    id: "A8hKbQ",
    description: "Label for Radio Button",
  },
  workloadIdentityFederation: {
    defaultMessage: "Workload Identity Federation",
    id: "ycA6Bu",
    description: "Label for Radio Button",
  },
});
