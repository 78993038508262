import {
  DataInventoryAggregateRequestSchema,
  DataInventoryAggregateResponseSchema,
  DataInventoryClassificationReportV2ResponseSchema,
  DataInventoryClassificationReportV3StructuredDataResponseSchema,
  DataInventoryObjectAggregateRequestSchema,
  DataInventoryObjectDetailsRequestSchema,
  DataInventoryObjectDetailsResponseSchema,
  DataInventoryObjectLevelAggregateSchema,
  DataInventoryResourceAggregateResponseSchema,
  DataInventoryServiceAggregateResponseSchema,
  DataInventorySnippetsReponseSchema,
  InventoryCountRequestSchema,
  InventoryCountResponseSchema,
  InventoryRequestSchema,
  InventoryResponseSchema,
  InventoryTrendSchema,
  QuotaResponseSchema,
  SummaryResponseSchema,
  type DataInventoryObjectAggregateRequest,
  type InventoryFiltersPostBodySchema,
} from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { determineApiVersion } from "./apiVersioning";

type InventoryRequest = z.infer<typeof InventoryRequestSchema>;
type FilterParam = z.infer<typeof InventoryFiltersPostBodySchema>;
type DataInventoryAggregateRequest = z.infer<
  typeof DataInventoryAggregateRequestSchema
>;
export type DataInventoryObjectDetailRequestType = z.infer<
  typeof DataInventoryObjectDetailsRequestSchema
>;

export const inventoryKeys = {
  all: [{ scope: "inventoryAll" }] as const,
  getInventory: ({
    apiVersion: requestedApiVersion,
    body: bodyIn,
    infiniteQuery = false,
    useV3Api,
  }: {
    apiVersion?: number;
    body: InventoryRequest;
    infiniteQuery?: boolean;
    useV3Api?: boolean;
  }) => {
    const apiVersion = determineApiVersion({ requestedApiVersion, useV3Api });

    const { timeRange: _timeRange, ...requestBodyNoTimeRange } = bodyIn;
    const body = {
      ...(apiVersion >= 3 ? requestBodyNoTimeRange : bodyIn),
    };

    return [
      {
        ...inventoryKeys.all[0],
        entity: "getInventory",
        infiniteQuery,
        apiVersion,
        body,
      },
    ] as const;
  },

  getSummary: ({
    apiVersion: requestedApiVersion,
    body: bodyIn,
  }: {
    apiVersion?: number;
    body: InventoryRequest;
  }) => {
    // endpoint doesn't exist for versions earlier than 4; forcing 4 at low end
    let apiVersion = determineApiVersion({
      requestedApiVersion,
      useV3Api: true,
    });
    apiVersion = Math.max(apiVersion, 4);

    const { timeRange: _timeRange, ...requestBodyNoTimeRange } = bodyIn;
    const body = {
      ...(apiVersion >= 3 ? requestBodyNoTimeRange : bodyIn),
    };

    return [
      {
        ...inventoryKeys.all[0],
        entity: "getSummary",
        apiVersion,
        body,
      },
    ] as const;
  },

  trend: ({ filters, timeRange }: InventoryRequest, useV3Api?: boolean) =>
    [
      {
        ...inventoryKeys.all[0],
        entity: "trends",
        filters,
        timeRange,
        useV3Api,
      },
    ] as const,
  dataInventoryAggregate: (body: DataInventoryAggregateRequest) => [
    {
      ...inventoryKeys.all[0],
      entity: "dataInventoryAggregate",
      body,
    },
  ],

  dataInventoryObjectDetail: (body: DataInventoryObjectDetailRequestType) => [
    {
      ...inventoryKeys.all[0],
      entity: "dataInventoryObjectDetail",
      body,
    },
  ],

  dataInventoryClassificationReportV2: (
    body: DataInventoryObjectDetailRequestType,
  ) => [
    {
      ...inventoryKeys.all[0],
      entity: "dataInventoryClassificationReportV2",
      body,
    },
  ],

  dataInventoryClassificationReportV3StructuredData: (
    body: DataInventoryObjectDetailRequestType,
  ) => [
    {
      ...inventoryKeys.all[0],
      entity: "dataInventoryClassificationReportV3StructuredData",
      body,
    },
  ],

  snippets: ({
    objectId,
    patternId,
  }: {
    objectId: DataInventoryObjectDetailRequestType["objectId"];
    patternId: string;
  }) => [
    {
      ...inventoryKeys.all[0],
      entity: "snippets",
      objectId,
      patternId,
    },
  ],

  dataInventoryObjectsAggregate: (
    body: DataInventoryObjectAggregateRequest,
  ) => [
    {
      ...inventoryKeys.all[0],
      entity: "dataInventoryObjectsAggregate",
      body,
    },
  ],

  quota: () => [
    {
      ...inventoryKeys.all[0],
      entity: "quota",
    },
  ],

  count: ({ filters }: { filters: FilterParam }) => [
    {
      ...inventoryKeys.all[0],
      entity: "count",
      body: { filters },
    },
  ],
} as const;

export const getInventory = async ({
  queryKey: [{ apiVersion, body }],
  pageParam,
}: QueryFunctionContext<
  ReturnType<(typeof inventoryKeys)["getInventory"]>
>) => {
  const prePath = apiVersion >= 4 ? "api/" : "";
  return jsonApi({
    path: `${prePath}v${apiVersion}/inventory`,
    body: { ...body, nextPageToken: pageParam as string | undefined },
    config: {
      method: "post",
    },
    requestSchema: InventoryRequestSchema,
    responseSchema: InventoryResponseSchema,
  });
};

export const getSummary = async ({
  queryKey: [{ apiVersion, body }],
}: QueryFunctionContext<ReturnType<(typeof inventoryKeys)["getSummary"]>>) => {
  return jsonApi({
    path: `api/v${apiVersion}/inventory/summary`,
    body,
    config: {
      method: "post",
    },
    requestSchema: InventoryRequestSchema,
    responseSchema: SummaryResponseSchema,
  });
};

export const inventoryTrend = async ({
  queryKey: [{ filters, timeRange, useV3Api }],
}: QueryFunctionContext<ReturnType<(typeof inventoryKeys)["trend"]>>) => {
  const api = useV3Api ? "v3" : "v2";

  return jsonApi({
    path: `${api}/inventory/trend`,
    body: useV3Api ? { filters } : { filters, timeRange },
    config: {
      method: "post",
    },
    requestSchema: InventoryRequestSchema,
    responseSchema: InventoryTrendSchema,
  });
};

export const getDataInventoryAggregate = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<
  ReturnType<(typeof inventoryKeys)["dataInventoryAggregate"]>
>) => {
  return jsonApi({
    path: "dlp/api/v1/inventory/objects/aggregate",
    body,
    config: {
      method: "post",
    },
    requestSchema: DataInventoryAggregateRequestSchema,
    responseSchema: DataInventoryAggregateResponseSchema,
  });
};

export const getDataInventoryServiceAggregate = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<
  ReturnType<(typeof inventoryKeys)["dataInventoryAggregate"]>
>) => {
  return jsonApi({
    path: "dlp/api/v1/inventory/objects/aggregate",
    body,
    config: {
      method: "post",
    },
    requestSchema: DataInventoryAggregateRequestSchema,
    responseSchema: DataInventoryServiceAggregateResponseSchema,
  });
};

export const getDataInventoryResourceAggregate = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<
  ReturnType<(typeof inventoryKeys)["dataInventoryAggregate"]>
>) => {
  return jsonApi({
    path: "dlp/api/v1/inventory/objects/aggregate",
    body,
    config: {
      method: "post",
    },
    requestSchema: DataInventoryAggregateRequestSchema,
    responseSchema: DataInventoryResourceAggregateResponseSchema,
  });
};

export const getDataInventoryObjectDetails = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<
  ReturnType<(typeof inventoryKeys)["dataInventoryObjectDetail"]>
>) => {
  return jsonApi({
    path: "dlp/api/v1/inventory/object/details",
    body,
    config: {
      method: "post",
    },
    requestSchema: DataInventoryObjectDetailsRequestSchema,
    responseSchema: DataInventoryObjectDetailsResponseSchema,
  });
};

export const getDataInventoryClassificationReportV2 = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<
  ReturnType<(typeof inventoryKeys)["dataInventoryClassificationReportV2"]>
>) => {
  return jsonApi({
    path: "dlp/api/v1/inventory/object/data-classification/reportv2",
    body,
    config: {
      method: "post",
    },
    requestSchema: DataInventoryObjectDetailsRequestSchema,
    responseSchema: DataInventoryClassificationReportV2ResponseSchema,
  });
};

export const getDataInventoryClassificationReportV3StructuredData = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<
  ReturnType<
    (typeof inventoryKeys)["dataInventoryClassificationReportV3StructuredData"]
  >
>) => {
  return jsonApi({
    path: "dlp/api/v1/inventory/object/data-classification/reportv3StructuredData",
    body,
    config: {
      method: "post",
    },
    requestSchema: DataInventoryObjectDetailsRequestSchema,
    responseSchema:
      DataInventoryClassificationReportV3StructuredDataResponseSchema,
  });
};

export const updateSnippets = async (
  body: DataInventoryObjectDetailRequestType,
) => {
  return jsonApi({
    path: "dlp/api/v1/inventory/snippet",
    body,
    config: {
      method: "post",
    },
    requestSchema: DataInventoryObjectDetailsRequestSchema,
    responseSchema: z.string(),
  });
};

export const getSnippets = async ({
  queryKey: [{ objectId, patternId }],
}: QueryFunctionContext<ReturnType<(typeof inventoryKeys)["snippets"]>>) => {
  return jsonApi({
    path: `dlp/api/v1/inventory/snippet/objects/${objectId}/datapattern/${patternId}`,
    requestSchema: z.void(),
    responseSchema: DataInventorySnippetsReponseSchema,
  });
};

export const getDataInventoryObjectsAggregate = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<
  ReturnType<(typeof inventoryKeys)["dataInventoryObjectsAggregate"]>
>) => {
  return jsonApi({
    path: "dlp/api/v1/inventory/objects/aggregate/l4",
    body,
    config: {
      method: "post",
    },
    requestSchema: DataInventoryObjectAggregateRequestSchema,
    responseSchema: DataInventoryObjectLevelAggregateSchema,
  });
};

export const getQuota = async () => {
  return jsonApi({
    path: "dlp/api/v1/inventory/data-scanned",
    requestSchema: z.void(),
    responseSchema: QuotaResponseSchema,
  });
};

export const enableSecurity = async () => {
  return jsonApi({
    path: "api/v1/provision/dlp",
    config: {
      method: "post",
    },
    requestSchema: z.void(),
    responseSchema: z.string(),
  });
};

export const getInventoryCount = async ({
  queryKey: [{ body }],
}: QueryFunctionContext<ReturnType<(typeof inventoryKeys)["count"]>>) => {
  return jsonApi({
    path: `api/v4/aggregation/assets-and-accounts-count`,
    body,
    config: {
      method: "post",
    },
    requestSchema: InventoryCountRequestSchema,
    responseSchema: InventoryCountResponseSchema,
  });
};
