import { lazy } from "react";
import { AlertsOverviewFilterSchema } from "requests";
import { createRoute, privateRootRoute, redirect } from "router";
import { z } from "zod";

const AlertsLayout = lazy(() =>
  import("./AlertsLayout").then((res) => ({
    default: res.AlertsLayout,
  })),
);

export const AlertsLayoutSearchSchema = z.object({
  filters: AlertsOverviewFilterSchema.catch({}).optional(),
  // TODO: Maybe when the router is upgraded this will work better
  // if I use .default("default") it works well with useSearch, but
  // then links to the page think the field is required
  // this fixes links, but useSearch thinks it is undefined
  viewId: z.string().catch("default").optional(),
  openAlertId: z.optional(z.string()),
  openAlertRules: z.optional(z.boolean()),
  searchText: z.string().optional(),
});

export type AlertsLayoutSearchType = z.infer<typeof AlertsLayoutSearchSchema>;

export const alertsLayoutRoute = createRoute({
  getParentRoute: () => privateRootRoute,
  path: "alerts",
  component: AlertsLayout,
  validateSearch: AlertsLayoutSearchSchema,
});

const PageNotFound = lazy(() =>
  import("../CatchAll/CatchAll").then((res) => ({ default: res.CatchAll })),
);

export const alertsIndexRoute = createRoute({
  getParentRoute: () => alertsLayoutRoute,
  path: "/",
  beforeLoad: ({ search }) => {
    throw redirect({ to: "/alerts/overview", search });
  },
});

export const alertsCatchAllRoute = createRoute({
  getParentRoute: () => alertsLayoutRoute,
  path: "$",
  component: PageNotFound,
  beforeLoad: ({ location: { pathname, search } }) => {
    if (pathname === "/alerts/reports") {
      throw redirect({ to: "/reports/alerts", replace: true, search });
    } else if (pathname === "/alerts/notification_templates") {
      throw redirect({
        to: "/settings/integrationAndNotification/notifications",
        replace: true,
        search,
      });
    }
  },
});
