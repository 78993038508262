import { z } from "zod";
import * as schemas from "../../schemas";
import { FilterSchema, TimeRangeSchema, TimeStampSchema } from "../../schemas";
import { CloudTypesWithOtherEnumSchema } from "../cloudType";
import { InventoryFilterSchema } from "../inventory";
import { SeverityEnumSchema } from "../policies";
import { SecurityIssuesSchema } from "../rql";

export const scanStatus = ["all", "passed", "failed"] as const;
export const ScanStatusEnumSchema = z.enum(scanStatus);

export const AssetExplorerFilterSchema = InventoryFilterSchema.extend({
  "resource.id": z.array(z.string()),
  "resource.name": z.array(z.string()),
  "scan.status": ScanStatusEnumSchema,
}).partial();

export const AssetScanInfoPostBodySchema = z.array(
  z.discriminatedUnion("name", [
    FilterSchema.extend({
      name: z.literal("includeEventForeignEntities"),
      value: z.enum(["true", "false"]),
    }),
    FilterSchema.extend({ name: z.literal("account.group") }),
    FilterSchema.extend({ name: z.literal("asset.class") }),
    FilterSchema.extend({
      name: z.literal("asset.severity"),
      value: SeverityEnumSchema,
    }),
    FilterSchema.extend({
      name: z.literal("vulnerability.severity"),
      value: SeverityEnumSchema,
    }),
    FilterSchema.extend({ name: z.literal("cloud.accountId") }),
    FilterSchema.extend({ name: z.literal("cloud.account") }),
    FilterSchema.extend({ name: z.literal("cloud.region") }),
    FilterSchema.extend({
      name: z.literal("cloud.service"),
    }),
    FilterSchema.extend({
      name: z.literal("cloud.type"),
      value: CloudTypesWithOtherEnumSchema,
    }),
    FilterSchema.extend({
      name: z.literal("policy.complianceStandard"),
    }),
    FilterSchema.extend({
      name: z.literal("policy.complianceRequirement"),
    }),
    FilterSchema.extend({
      name: z.literal("policy.complianceSection"),
    }),
    FilterSchema.extend({ name: z.literal("resource.group") }),
    FilterSchema.extend({ name: z.literal("resource.tagv2") }),
    FilterSchema.extend({ name: z.literal("resource.id") }),
    FilterSchema.extend({ name: z.literal("resource.name") }),
    FilterSchema.extend({ name: z.literal("resource.type") }),
    FilterSchema.extend({
      name: z.literal("scan.status"),
      value: ScanStatusEnumSchema,
    }),
    FilterSchema.extend({
      name: z.literal("decorateWithDerivedRRN"),
      value: z.boolean().nullish(),
    }),
    FilterSchema.extend({ name: z.literal("resourceList.tag") }),
  ]),
);

export const AssetScanInfoRequest = z.object({
  filters: AssetScanInfoPostBodySchema,
  limit: z.number().nonnegative(),
  pageToken: z.string().optional(),
  nextPageToken: z.string().optional(),
  timeRange: TimeRangeSchema.optional(),
});

export const PaginatedAssetItemResponse = z.object({
  accountId: z.string(),
  accountName: z.string(),
  alertStatus: z.object({
    critical: z.number().nonnegative().optional(),
    high: z.number().nonnegative().optional(),
    informational: z.number().nonnegative().optional(),
    low: z.number().nonnegative().optional(),
    medium: z.number().nonnegative().optional(),
  }),
  appNames: z.array(z.string()).nullish(),
  assetType: z.string(),
  cloudType: CloudTypesWithOtherEnumSchema,
  id: z.string(),
  name: z.string(),
  overallPassed: z.boolean().nullish(),
  regionId: z.string(),
  regionName: z.string(),
  resourceConfigJsonAvailable: z.boolean(),
  resourceDetailsAvailable: z.boolean(),
  rrn: z.string().optional(),
  findingCount: z.number().optional(),
  scannedPolicies: z
    .array(
      z.object({
        id: z.string(),
        labels: z.array(z.string()),
        name: z.string(),
        passed: z.boolean(),
        severity: SeverityEnumSchema,
      }),
    )
    .nullish(),
  unifiedAssetId: z.string().optional(),
  vulnerabilityStatus: z
    .object({
      critical: z.number().nonnegative().optional(),
      high: z.number().nonnegative().optional(),
      informational: z.number().nonnegative().optional(),
      low: z.number().nonnegative().optional(),
      medium: z.number().nonnegative().optional(),
    })
    .nullish(),
  matchedSecurityIssues: SecurityIssuesSchema.array().optional(),
  totalSecurityIssuesCount: z.number().optional(),
  matchingSecurityIssuesCount: z.number().optional(),
});

export const AssetScanInfoResponse = z.object({
  accuracyCount: z.boolean().optional(),
  nextPageToken: z.string().optional(),
  resources: z.array(PaginatedAssetItemResponse),
  sortAllowedColumns: z.array(z.string()).optional(),
  timestamp: schemas.TimeStampSchema,
  totalMatchedCount: z.number().nonnegative(),
});

export const AsyncDownloadRequestSchema = z.object({
  clientType: z.string(),
  payload: z.object({
    filters: AssetScanInfoPostBodySchema,
    timeRange: TimeRangeSchema.optional(),
  }),
  emailContext: z.object({
    metadata: z.object({ hashUrl: z.string(), paramUrl: z.string() }),
  }),
});

export const AsyncDownloadResponseSchema = z.object({
  jobId: z.string(),
  createdBy: z.string(),
  createdOn: z.number(),
  status: z.string(),
  statusUrl: z.string(),
  downloadUrl: z.string(),
});

export type AsyncDownloadRequest = z.infer<typeof AsyncDownloadRequestSchema>;

export type AssetsFiltersPostBodyType = z.infer<
  typeof AssetScanInfoPostBodySchema
>;

export type AssetsResponseType = z.infer<typeof AssetScanInfoResponse>;

export type AssetsPaginatedItemType = z.infer<
  typeof PaginatedAssetItemResponse
>;

export type AssetExplorerFilter = z.infer<typeof AssetExplorerFilterSchema>;

export const AssetCountRequestSchema = z.object({
  filters: AssetScanInfoPostBodySchema,
});

export const AssetCountResponseSchema = z.object({
  timestamp: TimeStampSchema,
  requestedTimestamp: TimeStampSchema,
  count: z.number(),
});

export type AssetCountResponse = z.infer<typeof AssetCountResponseSchema>;
