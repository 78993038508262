import { useMemo } from "react";
import { defineMessage, useIntl } from "react-intl";

import { type SelectItem } from ".";
import { areSelectItemValuesEqual } from "./utils";

const selectedItemsLabel = defineMessage({
  defaultMessage: "{selected} of {total} Selected",
  id: "JtVwlC",

  description:
    "Label for select all checkbox displaying current selected count",
});
const selectAllLabel = defineMessage({
  defaultMessage: "{total} Results",
  id: "2fir8G",

  description: "Label which shows how many results on a select",
});

type UseSelectAllProps = {
  enableSelectAll: boolean;
  items: SelectItem[];
  selectedValues: SelectItem["value"][];
  enableMultiSelect: boolean;
  totalCount?: number;
  isLoading?: boolean;
};

type UseSelectAllReturn = {
  allSelected: boolean;
  showSelectAll: boolean;
  selectAllItem: SelectItem;
};
export const useSelectAll = ({
  enableSelectAll,
  items,
  isLoading,
  selectedValues,
  enableMultiSelect,
  totalCount,
}: UseSelectAllProps): UseSelectAllReturn => {
  const intl = useIntl();
  const showSelectAll =
    enableSelectAll && enableMultiSelect && (isLoading || items.length > 1);
  const allSelected = useMemo(() => {
    if (!showSelectAll) return false;
    return items.every(
      (item) =>
        item.disabled ||
        selectedValues.some((v) => {
          return areSelectItemValuesEqual(v, item.value);
        }),
    );
  }, [items, selectedValues, showSelectAll]);

  const selectAllItem: SelectItem = useMemo(() => {
    if (!showSelectAll) return {} as SelectItem;
    const selectableItems = items.filter((item) => !item.disabled);
    const selectedFilteredItems = selectableItems.filter((item) =>
      selectedValues.some((v) => {
        return areSelectItemValuesEqual(v, item.value);
      }),
    ).length;
    return {
      value: intl.formatMessage(
        selectedFilteredItems ? selectedItemsLabel : selectAllLabel,
        {
          selected: selectedFilteredItems,
          total: totalCount || selectableItems.length,
        },
      ),

      indeterminate: !allSelected && selectedValues.length > 0,
    };
  }, [showSelectAll, totalCount, items, intl, allSelected, selectedValues]);

  return { allSelected, selectAllItem, showSelectAll };
};
