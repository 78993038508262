import { find, isNil } from "remeda";

import { type CloudTypes } from "./types";

export const ACCOUNT = "account";
export const WORKSPACE_DOMAIN = "workspace_domain";
export const WORKSPACE_DOMAIN_PAYLOAD = "workspaceDomain";
export const ACCOUNT_ID = "accountId";
export const ACCOUNT_TYPE = "accountType";
export const ACCOUNT_NAME = "accountName";
export const ACCOUNT_GROUP_MODE = "accountGroupMode";
export const ACTIVE_DIRECTORY = "activeDirectory";
export const ALIBABA_CLOUD = "alibaba_cloud";
export const ALL = "ALL";
export const AWS = "aws";
export const AWS_PARTITION = "awsPartition";
export const AZURE = "azure";
export const AZURE_GOV = "azure_gov";
export const AZURE_CHINA = "azure_china";
export const BUSINESS = "business";
export const CLIENT_ID = "clientId";
export const CLOUD_ACCOUNT = "cloudAccount";
export const CVCG = "Cloud Visibility Compliance and Governance";
export const CREATE_IAM_ROLE_CFT = "createIAMRoleCft";
export const DEFAULT_ACCOUNT_GROUP_ID = "defaultAccountGroupId";
export const DOWNLOAD_IAM_ROLE_CFT = "downloadIAMRoleCft";
export const EXCLUDE = "EXCLUDE";
export const DEPLOYMENT_TYPE = "deploymentType";
export const DISABLED = "disabled";
export const ENABLED = "enabled";
export const ENVIRONMENT_TYPE = "environmentType";
export const AUTHENTICATION_MODE = "authMode";
export const FEATURES = "features";
export const GCP = "gcp";
export const GROUP_IDS = "groupIds";
export const KEY = "key";
export const IBM = "ibm";
export const INCLUDE = "INCLUDE";
export const LOGGING_ACCOUNT_NAME = "loggingAccountName";
export const LOGGING_ACCOUNT_EXTERNAL_ID = "loggingAccountExternalId";
export const LOGGING_ACCOUNT_ID = "loggingAccountId";
export const LOGGING_ACCOUNT_ROLE = "loggingAccountRoleName";
export const LOGGING_ACCOUNT_ROLE_ARN = "loggingAccountRoleArn";
export const LOGGING_BUCKETS = "loggingAccountBuckets";
export const LOGGING_BUCKETS_LIST = "loggingAccountBucketsList";
export const LOGGING_STATUS = "status";
export const MASTER_SERVICE_ACCOUNT = "masterServiceAccount";
export const MONITOR_FLOW_LOGS = "monitorFlowLogs";
export const OCI = "oci";
export const ORGANIZATION = "organization";
export const OTHERS = "others";
export const REPOSITORIES = "repositories";
export const SUBSCRIPTION = "subscription";
export const SYSTEM_ADMIN = "System Admin";
export const SUBSCRIPTION_ID = "subscriptionId";
export const TENANT = "tenant";
export const TYPE = "type";
export const WIZARD_HEIGHT = "92vh";
export const ERRORS = "errors";
export const TENANT_ID = "tenantId";
export const VALUE = "value";
export const IS_EDIT = "isEdit";
export const NAME = "name";
export const APPLICATION_KEY = "applicationKey";
export const SERVICE_PRINCIPAL_ID = "servicePrincipalId";
export const APPLICATION_ID = "applicationId";
export const AZURE_ACTIVE_DIR_ONBOARD_ENABLED = "azureOnboardEnabled";
export const IS_RATE_LIMIT_HIT = "isRateLimitHit503";
export const DEFAULT_SERVICE_API_KEY = "********";
export const ROOT_SYNC_ENABLED = "rootSyncEnabled";
export const MANUAL = "MANUAL";
export const RECURSIVE = "RECURSIVE";
export const AUTO = "AUTO";
export const AUTO_MAP = "AUTOMAP";
export const AWS_OU_ROOT_Id = "rootId";
export const ROOT_ID_FROM_API = "rootIdFromAPI";
export const ROLE_ARN = "roleArn";
export const MEMBER_ROLE_NAME = "memberRoleName";
export const SERVICE_ACCOUNT = "service_account";
export const EXTERNAL_ACCOUNT = "external_account";
export const SERVICE_ACCOUNT_KEY = "service_account_key";
export const EXTERNAL_ACCOUNT_KEY = "external_account_key";
export const SET_EXTERNAL_ACCOUNT_KEY = "set_external_account_key";
export const SET_SERVICE_ACCOUNT_KEY = "set_service_account_key";
export const PRIVATE_KEY = "private_key";
export const PRIVATE_KEY_ID = "private_key_id";
export const PROJECT_ID = "projectId";
export const PROJECT_ID_WIF = "projectId_wif";
export const SIZE = "size";
export const CREDENTIALS = "credentials";
export const GCP_WIF_POOL_LOCATION = "wif_pool_location";
export const GCP_ENVIRONMENT_ID = "environment_id";
export const CLIENT_EMAIL = "client_email";
export const GCP_AUTHENTICATION_TYPE = "authenticationType";
export const FLOW_LOG_BUCKET = "flowLogBucket";
export const ORG_ID = "orgId";
export const COMPRESSION_ENABLED = "compressionEnabled";
export const DATA_FLOW_ENABLED_PROJECT_ID = "dataflowEnabledProject";
export const FLOW_LOG_STORAGE_BUCKET = "flowLogStorageBucket";
export const GCP_ACCOUNT_GROUP_CREATION_MODE = "accountGroupCreationMode";

export const CHINA_CLOUD_TYPES: CloudTypes = [ALIBABA_CLOUD, AWS, AZURE];
export const CLOUD_TYPES: CloudTypes = [
  ALIBABA_CLOUD,
  AWS,
  AZURE,
  GCP,
  IBM,
  OCI,
];
export const FREEMIUM_CLOUDS: CloudTypes = [AWS, AZURE];

export const SECURITY_CAPABILITIES_FEATURES_SORT_ORDER = [
  "compute-discovery",
  "compute-agentless",
  "compute-serverless-scan",
  "compute-auto-protect",
];

export const REMEDIATION = "Remediation";
export const DATA_SECURITY = "Data Security";
export const AGENTLESS_SCAN = "Agentless Scanning";
export const AUTO_PROTECT = "Auto Protect";
export const SERVERLESS_SCAN = "Serverless Function Scanning";
export const AGENTLESS_API_DISCOVERY = "Agentless API Discovery";
export const WORKLOAD_DISCOVERY = "Workload Discovery";
export const MISCONFIGURATIONS = "Cloud Visibility Compliance and Governance";
export const THREAT_DETECTION = "Threat Detection";
export const IDENTITY_SECURITY = "Identity Security";
export const COMPUTE_DISCOVERY = "Compute Discovery";
export const AUDIT_LOGS = "Audit Logs";
export const FLOW_LOGS = "Flow logs";
export const AWS_INSPECTOR = "AWS Inspector";
export const AWS_GUARD_DUTY = "AWS Guard Duty";
export const CONFIG = "Config";
export const AGENTLESS_DEFAULT_MEMBER_STATE =
  "Agentless Scanning Default Member State";
export const AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE =
  "Agentless API Discovery Default Member State";
export const SERVERLESS_DEFAULT_MEMBER_STATE =
  "Serverless Function Scanning Default Member State";

export const FEATURE_ITEMS = [
  {
    value: MISCONFIGURATIONS,
    children: ["compute-discovery", "aws-inspector", "aws-guardduty"],
  },
  {
    value: IDENTITY_SECURITY,
  },
  {
    value: AGENTLESS_SCAN,
    children: ["compute-agentless"],
  },
  {
    value: THREAT_DETECTION,
  },
  {
    value: SERVERLESS_SCAN,
    children: ["compute-serverless-scan"],
  },
  {
    value: AUTO_PROTECT,
    children: ["compute-auto-protect"],
  },
  {
    value: DATA_SECURITY,
    children: ["data-security"],
  },
  {
    value: REMEDIATION,
    children: ["remediation"],
  },
  {
    value: AGENTLESS_API_DISCOVERY,
    children: ["agentless-api-discovery"],
  },
];

export const CHINA = "china";
export const GOV_CLOUD = "gov_cloud";
export const GLOBAL_INT = "global";
export const FINANCE = "finance";

/* Wizard Steps Constants */
/**************************/
export const GET_STARTED = "getStarted";
export const CONFIGURE_ACCOUNT = "configureAccount";
export const REVIEW_STATUS = "reviewStatus";
export const CONFIGURE_DATA_SECURITY = "configureDataSecurity";
export const DEFINE_FW = "defineFw";
export const CONFIGURE_FW = "configureFw";
export const ADD_DETAILS = "addDetails";
export const CUSTOM_CONFIGURATION = "customConfiguration";
export const SUMMARY = "summary";

const ICP_HOST_NAME_LIST = [".cn"];

export const IS_ICP_SUPPORTED = () => {
  return !isNil(
    find(ICP_HOST_NAME_LIST, function (domain) {
      return window.location.hostname.indexOf(domain) !== -1;
    }),
  );
};

/* Member Account Context Action Types */
/**************************/
export const SET_FTU_FIELD_ERRORS = "set_ftu_field_errors";
export const SET_FTU_FIELD_VALUE = "set_ftu_field_value";
export const CLEAR_FTU_FIELD_ERRORS = "clear_ftu_field_errors";
export const FTU_FORM_DATA = "ftu_form_data";

/* AWS Choose Monitored Projects Types */
/**************************/
export const AWS_GOV_ROLE_VALIDATION_CRITERIA = "arn:aws-us-gov:iam::";
export const AWS_ROLE_VALIDATION_CRITERIA = "arn:aws:iam::";
export const AWS_ROLE_VALIDATION_CRITERIA_CHINA = "arn:aws-cn:iam::";
export const AWS_OU_DEFAULT_EXPANDED_NODES = "AWS ancestors of selected nodes";
export const AWS_OU_FOLDER_VIEW_CREDENTIALS_ERROR =
  "Credentials don't have permission to view OUs error";
export const AWS_OU_HIERARCHY_SELECTION = "hierarchySelection";
export const AWS_OU_LOAD_MORE_NO_TOKEN = {};
export const AWS_OU_LOADED_NODES = "AWS loaded nodes";
export const AWS_OU_PREVIOUSLY_SELECTED_NODES_NOT_FOUND =
  "AWS nodes previously selected, but not found";
export const AWS_OU_PROJECT_SELECT_MODE = "AWS project select mode";
export const AWS_OU_SELECT_PROJECTS_INITIALIZED =
  "AWS select projects initialized";
export const AWS_OU_TREE_MAP = "AWS tree map";
export const AWS_OU_SELECTED_NODES = "AWS selected nodes";
export const AWS_OU_SELECTED_NODES_INITIAL = "AWS initially selected nodes";
export const UPDATE_AWS_OU_NODES = "Update AWS nodes";
export const INIT_AWS_OU_TREE_MAP = "Initialize AWS tree map";
export const AWS_OU_IS_LOADING = "isAwsOULoading";

/* AZURE Choose Monitored Projects Types */
/**************************/
export const AZURE_DEFAULT_EXPANDED_NODES = "AZURE ancestors of selected nodes";
export const AZURE_FOLDER_VIEW_CREDENTIALS_ERROR =
  "Credentials don't have permission to view folders error";
export const AZURE_HIERARCHY_SELECTION = "hierarchySelection";
export const AZURE_LOAD_MORE_NO_TOKEN = {};
export const AZURE_LOADED_NODES = "AZURE loaded nodes";
export const AZURE_PREVIOUSLY_SELECTED_NODES_NOT_FOUND =
  "AZURE nodes previously selected, but not found";
export const AZURE_PROJECT_SELECT_MODE = "AZURE project select mode";
export const AZURE_SELECT_PROJECTS_INITIALIZED =
  "AZURE select projects initialized";
export const AZURE_SELECTED_NODES = "AZURE selected nodes";
export const AZURE_SELECTED_NODES_INITIAL = "AZURE initially selected nodes";
export const AZURE_TREE_MAP = "AZURE tree map";
export const UPDATE_AZURE_NODES = "Update Azure nodes";
export const INIT_AZURE_TREE_MAP = "Initialize Azure tree map";
export const AZURE_OU_IS_LOADING = "isAzureOULoading";
/* GCP Choose Monitored Projects Types */
/**************************/
export const GCP_DEFAULT_EXPANDED_NODES = "GCP ancestors of selected nodes";
export const GCP_FOLDER_VIEW_CREDENTIALS_ERROR =
  "Credentials don't have permission to view folders error";
export const GCP_HIERARCHY_SELECTION = "hierarchySelection";
export const GCP_LOAD_MORE_NO_TOKEN = {};
export const GCP_LOADED_NODES = "GCP loaded nodes";
export const GCP_PREVIOUSLY_SELECTED_NODES_NOT_FOUND =
  "GCP nodes previously selected, but not found";
export const GCP_PROJECT_SELECT_MODE = "GCP project select mode";
export const GCP_SELECTED_NODES = "GCP selected nodes";
export const GCP_SELECTED_NODES_INITIAL = "GCP initially selected nodes";
export const GCP_TREE_MAP = "GCP tree map";
export const GCP_SELECT_PROJECTS_INITIALIZED =
  "GCP select projects initialized";
export const UPDATE_GCP_NODES = "Update GCP nodes";
export const INIT_GCP_TREE_MAP = "Initialize GCP tree map";
export const SET_IS_GCP_WIF_ENABLED = "set_is_gcp_wif_enabled";
export const IS_GCP_WIF_ENABLED = "isGCPWifEnabled";
export const ORG_NAME = "orgName";
export const BYTE_SCALE = 1024;
export const GCP_OU_IS_LOADING = "isGCPOULoading";
