import { useQuery } from "@tanstack/react-query";
import {
  Navigate,
  Outlet,
  useNavigate,
  useRouterState,
} from "@tanstack/react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { getDlpStatus, tenantKeys } from "requests";
import {
  Body,
  Bold,
  Button,
  EmptyStateLayout,
  Link,
  NoAlertRulesSVG,
  PageHeader,
  PageLayout,
  Tab,
  TabList,
} from "ui";
import { useFreemiumModal } from "../../inventory/data-inventory/freemium/FreemiumModal";

export function DataSettingsLayout() {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    location: { pathname },
  } = useRouterState();
  const { openModal: openFreemiumModal } = useFreemiumModal();

  const { data, isLoading } = useQuery({
    queryKey: tenantKeys.dlpStatus(),
    queryFn: getDlpStatus,
  });
  const hasAccess = data?.dlpTenantId;
  if (isLoading) return <></>;

  return (
    <PageLayout
      documentTitle={[
        intl.formatMessage({
          defaultMessage: "Data Security",
          id: "JSAcTp",
          description: "data security page title",
        }),
      ]}
      header={
        <PageHeader
          title={
            <FormattedMessage
              defaultMessage="Data Security Settings"
              id="wKHJJp"
              description="data security settings tab title"
            />
          }
        />
      }
      overflow
      noPadding
      tabList={
        <TabList activeTabId={pathname}>
          {pathname === "/settings/data" && (
            <Navigate to="/settings/data/scan" replace />
          )}
          <Tab
            id="/settings/data/scan"
            onClick={() => navigate({ to: "/settings/data/scan" })}
          >
            <FormattedMessage
              defaultMessage="Scan Settings"
              id="F/eK57"
              description="data security scan settings tab title"
            />
          </Tab>
          <Tab
            id="/settings/data/snippetMasking"
            onClick={() => navigate({ to: "/settings/data/snippetMasking" })}
          >
            <FormattedMessage
              defaultMessage="Snippet Masking"
              id="PiIB3E"
              description="data security snippet masking tab title"
            />
          </Tab>
          <Tab
            id="/settings/data/dataProfiles"
            onClick={() => navigate({ to: "/settings/data/dataProfiles" })}
          >
            <FormattedMessage
              defaultMessage="Data Profiles"
              id="wBeO1M"
              description="data security data profiles tab title"
            />
          </Tab>
          <Tab
            id="/settings/data/dataPatterns"
            onClick={() => navigate({ to: "/settings/data/dataPatterns" })}
          >
            <FormattedMessage
              defaultMessage="Data Patterns"
              id="2uSiHS"
              description="data security data patterns tab title"
            />
          </Tab>
        </TabList>
      }
    >
      {!hasAccess ? (
        <EmptyStateLayout
          graphic={<NoAlertRulesSVG style={{ maxWidth: "15rem" }} />}
          heading={
            <Bold>
              <FormattedMessage
                defaultMessage="You Haven't Enabled Data Security"
                id="DxR4Dd"
                description="Title when data security is not enabled"
              />
            </Bold>
          }
          description={
            <Body addClassName="mt-2 flex w-96 text-xs" as="span">
              <FormattedMessage
                defaultMessage="You have not enabled Data Security. For visibility into what is stored in your cloud storage accounts, enable data security and discover public exposure, sensitive data and protect against malware."
                id="xe/000"
                description="Title when data security is not enabled"
              />
            </Body>
          }
          actions={
            <Body as="div" addClassName="flex gap-x-4 gap-y-2">
              <Link
                href="https://docs.prismacloud.io/en/classic/cspm-admin-guide/connect-your-cloud-platform-to-prisma-cloud/onboard-aws/configure-data-security"
                external
              >
                <FormattedMessage
                  defaultMessage="Learn More"
                  id="39+2FT"
                  description="Learn More button"
                />
              </Link>
              <Button onClick={() => openFreemiumModal()}>
                <FormattedMessage
                  defaultMessage="Enable Data Security"
                  id="g3XV3c"
                  description="Enable Data Security button"
                />
              </Button>
            </Body>
          }
        />
      ) : (
        <Outlet />
      )}
    </PageLayout>
  );
}
