import {
  AwsFeatureStatusResponseSchema,
  AzureFeatureStatusResponseSchema,
  GcpFeatureStatusResponseSchema,
  MetaInfoResponseSchema,
} from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";

export const featureStatuskeys = {
  all: [{ scope: "allFeatures" }] as const,
  aws: () =>
    [
      {
        ...featureStatuskeys.all[0],
        entity: "aws",
      },
    ] as const,

  gcp: () =>
    [
      {
        ...featureStatuskeys.all[0],
        entity: "gcp",
      },
    ] as const,

  azure: () =>
    [
      {
        ...featureStatuskeys.all[0],
        entity: "azure",
      },
    ] as const,

  metaInfo: () =>
    [
      {
        ...featureStatuskeys.all[0],
        entity: "metaInfo",
      },
    ] as const,
};

export const awsFeatureStatus = async (
  _props: QueryFunctionContext<ReturnType<(typeof featureStatuskeys)["aws"]>>,
) => {
  return jsonApi({
    path: "feature/status/aws",
    requestSchema: z.void(),
    responseSchema: AwsFeatureStatusResponseSchema,
  });
};

export const getMetaInfo = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof featureStatuskeys)["metaInfo"]>
  >,
) => {
  return jsonApi({
    path: "meta_info",
    requestSchema: z.void(),
    responseSchema: MetaInfoResponseSchema,
  });
};

export const gcpFeatureStatus = async (
  _props: QueryFunctionContext<ReturnType<(typeof featureStatuskeys)["gcp"]>>,
) => {
  return jsonApi({
    path: "feature/status/gcp",
    requestSchema: z.void(),
    responseSchema: GcpFeatureStatusResponseSchema,
  });
};

export const azureFeatureStatus = async (
  _props: QueryFunctionContext<ReturnType<(typeof featureStatuskeys)["azure"]>>,
) => {
  return jsonApi({
    path: "feature/status/azure",
    requestSchema: z.void(),
    responseSchema: AzureFeatureStatusResponseSchema,
  });
};
