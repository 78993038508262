import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AmazonElasticKubernetesServiceIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Amazon Elastic Kubernetes Service",
    id: "nnO2mI",

    description: "Amazon Elastic Kubernetes Service icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M50 90C49.7406 90.0018 49.4852 89.9354 49.2596 89.8074L15.2006 70.1421C14.9746 70.0116 14.7872 69.8238 14.6571 69.5975C14.5271 69.3713 14.4592 69.1147 14.4602 68.8538V29.5231C14.4592 29.2622 14.5271 29.0056 14.6571 28.7794C14.7872 28.5531 14.9746 28.3653 15.2006 28.2348L46.2979 10.1984C46.523 10.0684 46.7784 10 47.0383 10C47.2983 10 47.5536 10.0684 47.7787 10.1984C48.0047 10.3289 48.1922 10.5167 48.3222 10.743C48.4523 10.9692 48.5202 11.2258 48.5192 11.4867V28.531C48.5202 28.7919 48.4523 29.0485 48.3222 29.2747C48.1922 29.501 48.0047 29.6888 47.7787 29.8193L32.2301 38.8967V59.4358L50 69.6979L65.5042 60.8129C65.7293 60.683 65.9847 60.6145 66.2446 60.6145C66.5045 60.6145 66.7599 60.683 66.985 60.8129L81.7933 69.3573C82.0211 69.4864 82.2107 69.6737 82.3426 69.9001C82.4745 70.1264 82.544 70.3836 82.544 70.6456C82.544 70.9075 82.4745 71.1648 82.3426 71.3911C82.2107 71.6175 82.0211 71.8048 81.7933 71.9339L50.7404 89.8074C50.5147 89.9354 50.2594 90.0018 50 90ZM17.4219 67.9949L50 86.8162L78.1356 70.5271L66.289 63.6857L50.7404 72.7039C50.5153 72.8339 50.2599 72.9023 50 72.9023C49.74 72.9023 49.4847 72.8339 49.2596 72.7039L30.0089 61.583C29.7829 61.4525 29.5954 61.2646 29.4654 61.0384C29.3353 60.8122 29.2674 60.5556 29.2684 60.2946V38.0823C29.2674 37.8214 29.3353 37.5648 29.4654 37.3385C29.5954 37.1123 29.7829 36.9244 30.0089 36.794L45.5575 27.7461V14.0485L17.4219 30.3376V67.9949Z"
        className="fill-[url(#paint0_linear_6619_101596)] dark:fill-white"
      />
      <path
        d="M84.0588 66.9288C83.7994 66.9306 83.5441 66.8642 83.3184 66.7363L68.5102 58.2216C68.2826 58.0986 68.0973 57.9099 67.9785 57.6801C67.8596 57.4503 67.8127 57.1901 67.8438 56.9333V38.8968L52.2211 30.0119C51.9952 29.8814 51.8077 29.6936 51.6776 29.4673C51.5476 29.2411 51.4797 28.9845 51.4807 28.7236V11.6497C51.4797 11.3888 51.5476 11.1322 51.6776 10.9059C51.8077 10.6797 51.9952 10.4918 52.2211 10.3614C52.4489 10.2395 52.7032 10.1758 52.9615 10.1758C53.2199 10.1758 53.4742 10.2395 53.702 10.3614L84.7993 28.2349C85.0252 28.3654 85.2127 28.5532 85.3427 28.7795C85.4728 29.0057 85.5407 29.2623 85.5397 29.5232V65.448C85.5397 65.8407 85.3836 66.2174 85.1059 66.4951C84.8282 66.7728 84.4516 66.9288 84.0588 66.9288ZM70.7314 56.0892L82.578 62.8714V30.3821L54.4424 14.2115V27.8795L69.991 36.7645C70.2148 36.8937 70.4009 37.0792 70.5308 37.3026C70.6607 37.526 70.7299 37.7795 70.7314 38.038V56.0892Z"
        className="fill-[url(#paint1_linear_6619_101596)] dark:fill-white"
      />
      <path
        d="M42.5959 60.354V38.1416H45.5576V48.2556L55.1829 38.1416H59.0923L49.0523 48.9516L59.966 60.354H55.9234L45.5576 49.9882V60.354H42.5959Z"
        className="fill-[url(#paint2_linear_6619_101596)] dark:fill-white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6619_101596"
          x1={14.4602}
          y1={10}
          x2={93.4308}
          y2={77.2077}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6619_101596"
          x1={51.4807}
          y1={10.1758}
          x2={101.562}
          y2={40.2308}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6619_101596"
          x1={42.5959}
          y1={38.1416}
          x2={64.1533}
          y2={54.9994}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
