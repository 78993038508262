import { type ReactElement, type ReactNode } from "react";
import { classNames } from "utils";

import { type CellInstance, type Data, type RowInstance } from "../../types";
import { type TableLayoutProps } from "../TableLayout";
import { RowExpansionToggle } from "./RowExpansionToggle";

export interface CellProps<D extends Data> {
  cell: CellInstance<D>;
  row: RowInstance<D>;
  last?: boolean;
}
export interface CellWrapperProps<D extends Data>
  extends Omit<CellProps<D>, "row"> {
  children: ReactNode;
}

export function Cell<D extends Data>({
  cell,
  row,
  last = false,
  ...rest
}: CellProps<D> & Pick<TableLayoutProps, "appearance">): ReactElement {
  return (
    <CellWrapper cell={cell} last={last} {...rest}>
      {cell.isGrouped ? (
        // If it's a grouped cell, add an expander and row count
        <>
          <span className="flex-none">
            <RowExpansionToggle row={row} />
          </span>
          {cell.render("Cell")}
          <span className="flex-none pl-1">({row.subRows.length})</span>
        </>
      ) : cell.isAggregated ? (
        // If the cell is aggregated, use the Aggregated
        // renderer for cell
        cell.render("Aggregated")
      ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
        // Otherwise, just render the regular cell
        cell.render("Cell")
      )}
    </CellWrapper>
  );
}

export function CellWrapper<D extends Data>({
  appearance = "primary",
  cell,
  last = false,
  children,
}: CellWrapperProps<D> & Pick<TableLayoutProps, "appearance">): ReactElement {
  const classes = classNames(
    appearance === "card" &&
      !last &&
      "relative after:absolute after:inset-y-2 after:right-0 after:block after:border after:bg-blue-300 after:dark:border-blue-steel-850",
    last && "grow",
  );
  const cellClassNames = classNames(
    "flex h-full w-full items-center px-4 py-1 leading-tight",
  );
  const { key, ...cellProps } = cell.getCellProps();
  return (
    <div
      data-table-column-id={cell.column.id}
      data-testid={`table-column-${cell.column.id}`}
      className={classes}
      key={key}
      {...cellProps}
    >
      <div className={cellClassNames}>{children}</div>
    </div>
  );
}
