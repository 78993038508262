import { z } from "zod";
import {
  ActionPlanFilterSchema,
  ActionPlanSortByEnumSchema,
} from "../actionPlan";
import { AlertsGroupBySchema, AlertsOverviewFilterSchema } from "../alerts";
import { ComplianceStandardsFiltersSchema } from "../compliance";
import {
  DashboardGlobalFilterSchema,
  PreferencesChartSchema,
} from "../dashboard";
import { FilterStateSchema } from "../listItems/schemas";
import { PolicyFilterSchema } from "../policies";
import { TablePreferencesSchema } from "../preferences";
import { SavedViewBaseSchema } from "./schemas";

export const AlertSavedViewSchema = SavedViewBaseSchema.extend({
  settings: z.object({
    filterState: FilterStateSchema.extend({
      filters: AlertsOverviewFilterSchema,
    }).optional(),
    tableState: TablePreferencesSchema.optional(),
    sortBy: z.enum(["alertCount", "severity"]).optional(),
    sortOrder: z.enum(["asc", "desc"]).optional(),
    groupBy: AlertsGroupBySchema.optional(),
    displayFormat: z.enum(["cards", "table"]).optional(),
  }),
});
export type AlertSavedView = z.infer<typeof AlertSavedViewSchema>;

export const PolicySavedViewSchema = SavedViewBaseSchema.extend({
  settings: z.object({
    filterState: FilterStateSchema.extend({
      filters: PolicyFilterSchema,
    }).optional(),
    tableState: TablePreferencesSchema.optional(),
  }),
});
export type PolicySavedView = z.infer<typeof PolicySavedViewSchema>;

export const ComplianceStandardSavedViewSchema = SavedViewBaseSchema.extend({
  settings: z.object({
    filterState: FilterStateSchema.extend({
      filters: ComplianceStandardsFiltersSchema,
    }).optional(),
    tableState: TablePreferencesSchema.optional(),
  }),
});
export type ComplianceStandardSavedView = z.infer<
  typeof ComplianceStandardSavedViewSchema
>;

export const DashboardsSavedViewSchema = SavedViewBaseSchema.extend({
  settings: z.object({
    charts: z.array(PreferencesChartSchema).optional(),
    filterState: FilterStateSchema.extend({
      filters: DashboardGlobalFilterSchema.optional(),
    }).optional(),
  }),
});
export type DashboardSavedView = z.infer<typeof DashboardsSavedViewSchema>;

export const ActionPlansSavedViewSchema = SavedViewBaseSchema.extend({
  settings: z.object({
    filterState: FilterStateSchema.extend({
      filters: ActionPlanFilterSchema.optional(),
    }).optional(),
    alertsTableState: TablePreferencesSchema.optional(),
    assetsTableState: TablePreferencesSchema.optional(),
    sortBy: ActionPlanSortByEnumSchema.optional(),
    sortOrder: z.enum(["asc", "desc"]).optional(),
  }),
});
export type ActionPlansSavedView = z.infer<typeof ActionPlansSavedViewSchema>;
