import { FormattedMessage, useIntl } from "react-intl";

import { Accordion, AccordionPanel } from "ui";

import { useEnabledFeatures } from "./useEnabledFeatures";

import {
  CheckboxRow,
  Row,
  SelectAllCheckbox,
  useOperationSelectAll,
} from "../components";

import { type Features } from "requests";

export const dataSecurityFeatures: Features[] = [
  {
    featureName: "dlpDataProfile",
    operations: {
      CREATE: false,
      READ: false,
      UPDATE: false,
      DELETE: false,
    },
  },
  {
    featureName: "dlpDataPattern",
    operations: {
      CREATE: false,
      READ: false,
      UPDATE: false,
      DELETE: false,
    },
  },
  {
    featureName: "dlpSnippet",
    operations: {
      READ: false,
      UPDATE: false,
    },
  },
  {
    featureName: "dlpResource",
    operations: {
      READ: false,
      UPDATE: false,
    },
  },
  {
    featureName: "dlpDataInventory",
    operations: {
      READ: false,
    },
  },
  {
    featureName: "dlpDataDashboard",
    operations: {
      READ: false,
    },
  },
];

export function DataSecuritySection() {
  const intl = useIntl();
  const { isLoading, isFeatureEnabled } = useEnabledFeatures();
  const availableFeatures = dataSecurityFeatures.filter((feature) =>
    isFeatureEnabled(feature.featureName),
  );

  const { selectionStatus, onClickSelectAll } =
    useOperationSelectAll(availableFeatures);

  if (isLoading) return null;

  if (!availableFeatures.length) {
    return null;
  }

  return (
    <Accordion allowNoneOpen allowMultipleOpen defaultOpenPanels={[]}>
      <AccordionPanel
        title={intl.formatMessage({
          defaultMessage: "Data Security",
          id: "8uecwg",
          description: "Assign permissions section header",
        })}
        noPadding
        testId="permission-accordion"
      >
        <div className="divide-y divide-primary">
          <Row
            label={intl.formatMessage({
              defaultMessage: "Select All",
              id: "aYkfGO",
              description: "Select all checkbox row",
            })}
            addClassName="border-b-2"
            read={
              <SelectAllCheckbox
                status={selectionStatus.read}
                onChange={() => {
                  onClickSelectAll("READ");
                }}
              >
                <FormattedMessage
                  defaultMessage="Select view for all data security rows"
                  id="FTW5Gw"
                  description="Permission group data security section header"
                />
              </SelectAllCheckbox>
            }
            create={
              <SelectAllCheckbox
                status={selectionStatus.create}
                onChange={() => {
                  onClickSelectAll("CREATE");
                }}
              >
                <FormattedMessage
                  defaultMessage="Select create for all data security rows"
                  id="YKL6oT"
                  description="Permission group data security section header"
                />
              </SelectAllCheckbox>
            }
            update={
              <SelectAllCheckbox
                status={selectionStatus.update}
                onChange={() => {
                  onClickSelectAll("UPDATE");
                }}
              >
                <FormattedMessage
                  defaultMessage="Select update for all data security rows"
                  id="k9mvFr"
                  description="Permission group data security section header"
                />
              </SelectAllCheckbox>
            }
            del={
              <SelectAllCheckbox
                status={selectionStatus.delete}
                onChange={() => {
                  onClickSelectAll("DELETE");
                }}
              >
                <FormattedMessage
                  defaultMessage="Select delete for all data security rows"
                  id="rB+A/2"
                  description="Permission group data security section header"
                />
              </SelectAllCheckbox>
            }
          />

          {availableFeatures.map((feature) => {
            return <CheckboxRow key={feature.featureName} feature={feature} />;
          })}
        </div>
      </AccordionPanel>
    </Accordion>
  );
}
